import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    Grid, Chip, FormControlLabel, Checkbox, Select, MenuItem, IconButton, Dialog
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { imgMax } from './assets'
import { Link } from "react-router-dom";
import WithAuthLayout from '../../landingpage/src/CarouselDisplay.web';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Country } from "country-state-city";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmationModal from './ConfirmationModal.web';
// Customizable Area End


// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            "width": "198px",
            "padding": "6px 10px",
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "16px",
            "borderRadius": "36px",
            fontWeight: 400,
            textTransform: "none",
            "height": "49px",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                height: "35px",
                fontSize: "14px",
            }
        },
    }),
)((props: any) => <Button {...props} />);

export const CustomSelect: any = withStyles((theme: Theme) =>
    createStyles({
        icon: {
            right: "10px",
            pointerEvents: 'none'
        },
        select: {
            paddingRight: "34px !important",
            '&:focus': {
                backgroundColor: 'transparent'
            }
        }
    }),
)((props: any) => <Select {...props} />);
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";

export class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    requirements = [
        { regex: /.{8,}/, message: "At least 8 characters long" },
        { regex: /[A-Z]/, message: "At least 1 uppercase character" },
        { regex: /[a-z]/, message: "At least 1 lowercase character" },
        { regex: /\d/, message: "At least 1 number" },
        { regex: /[!@#$%^&*()\-_=+{};:,<.>]/, message: "At least 1 special character" }
    ];

    GenInput = (formikProp: any, inputLabel: string, inputName: string, inputType: string, inputPlaceholder: string, enableEyeIcon?: boolean, keyRule?: any) => {
        return (
            <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>{inputLabel}</Typography>
                <input
                    data-test-id={`${inputName}testID`}
                    placeholder={inputPlaceholder}
                    value={formikProp.values[inputName]}
                    onKeyDown={(e) => keyRule ? keyRule.fn(e, keyRule.rule) : null}
                    onChange={formikProp.handleChange}
                    style={webStyles.inputRage}
                    type={inputType}
                    name={inputName}
                />

                {enableEyeIcon && <IconButton style={webStyles.eyeIcon as React.CSSProperties}
                    data-test-id="eyeIconBtn"
                    onClick={() => this.handlePasswordToggle(inputName)}>
                    {this.state.passwordEyeToggleForPassword[inputName] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>}

                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Typography style={webStyles.commonErrorMessage} color='error' variant='subtitle2'>
                        <span><InfoOutlinedIcon color='error' fontSize='small' /></span>
                        {formikProp.errors[inputName]}
                    </Typography>
                }

            </Box>
        )
    }

    GenSelectableInput = (formikProp: any, inputLabel: string, inputName: string, optionArray: any) => {
        return (
            <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>{inputLabel}</Typography>

                <Box sx={webStyles.selectInputWrapper}>
                    {optionArray.map((val: any) => (
                        <Chip
                            style={formikProp.values[inputName] == val ? webStyles.selectableActiveInput : webStyles.selectInput}
                            label={<Typography style={formikProp.values[inputName] == val ? { color: "#FFF" } : {}} variant='h5'>{val}</Typography>}
                            clickable
                            onClick={() => this.handleChangeSelectableInput(inputName, val, formikProp.handleChange)}
                            variant="outlined"
                            color='secondary'
                            data-test-id="selectableInput"
                        />
                    ))
                    }
                </Box>

                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Grid style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-end'>
                        <InfoOutlinedIcon color='error' fontSize='small' />
                        <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                            {formikProp.errors[inputName]}
                        </Typography>
                    </Grid>
                }


            </Box>
        )
    }

    GenDropDownInput = (formikProp: any, inputName: string,) => {
        return (
            <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>Country</Typography>

                <Box>
                    <CustomSelect
                        renderValue={this.GenSelectedOption}
                        style={webStyles.dropdownRage}
                        autoWidth
                        labelWidth={1}
                        name={inputName}
                        disableUnderline
                        onChange={formikProp.handleChange}
                        value={formikProp.values[inputName]}
                        data-test-id={`selectInput_${inputName}`}
                    >
                        <MenuItem disabled value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                            Select Country
                        </MenuItem>
                        {Country.getAllCountries().map((data: any) => (
                            <MenuItem value={data} style={{ backgroundColor: "#FFF" }}>
                                {this.GenFlagOption(data, "body1")}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>

                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Grid style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-end'>
                        <InfoOutlinedIcon color='error' fontSize='small' />
                        <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                            {formikProp.errors[inputName]}
                        </Typography>
                    </Grid>
                }


            </Box>
        )
    }

    GenSelectedOption = (data: any) => {
        return (
            <Box sx={{ width: { xs: "100px", sm: "140px" }, overflow: "hidden" }}>
                {data == "placeholder" ? (
                    <Typography variant='body2'>
                        Select Country
                    </Typography>
                ) : this.GenFlagOption(data, 'body2')}
            </Box>
        )
    }

    GenFlagOption = (data: any, textVariant: any) => {
        return (
            <Box sx={webStyles.optionWrapper}>
                <img
                    width={36}
                    height={18}
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.isoCode}.svg`}
                />
                <Typography variant={textVariant}>{data.name}</Typography>
            </Box>
        )
    }

    GenCheckbox = (formikProp: any, inputLabel: any, inputName: string,) => {
        return (
            <FormControlLabel
                control={<Checkbox color='primary' name={inputName} checked={formikProp.values[inputName]} onChange={formikProp.handleChange} />}
                label={
                    <Box data-test-id="checkBoxWrapper" sx={{ position: "relative" }}>
                        {inputLabel}

                        {formikProp.touched[inputName] && formikProp.errors[inputName] && <Typography data-test-id="t6" style={webStyles.errorMessage as React.CSSProperties} color='error' variant='subtitle2'>
                            <span><InfoOutlinedIcon color='error' fontSize='small' /></span>
                            {formikProp.errors[inputName]}
                        </Typography>}

                    </Box>
                }
            />
        )
    }

    RenderChip = (isSelected: boolean, onClickHandler: () => void, label: string) => (
        <Chip
            onClick={onClickHandler}
            data-test-id="chipUser"
            style={isSelected ? webStyles.activeChipRage : webStyles.chipRage}
            label={<Typography variant='body1'>{label}</Typography>}
        />
    );

    PasswordRequirements = (requirements: any, newPassword: any) => {

        const validationResults = requirements.map((data: any) => data.regex.test(newPassword));

        let status = requirements.map((data: any, index: any) => {
            const Icon = !validationResults[index] ? InfoOutlinedIcon : CheckCircleIcon;
            const style = validationResults[index] ? webStyles.infoMessage : webStyles.passwordErrorMessage;

            return (
                <Typography data-test-id="t1" style={style} variant='subtitle2' key={index}>
                    <span><Icon color='error' fontSize='small' /></span>
                    {data.message}
                </Typography>
            );
        });

        return (
            <Box data-test-id="t2" sx={webStyles.passwordWarning}>
                <Typography data-test-id="t1" style={webStyles.underlineMessage} variant='subtitle2'>
                    <span><InfoOutlinedIcon data-test-id="iconX" color='error' fontSize='small' /></span>
                    Password Requirements:
                </Typography>
                {status}
            </Box>
        );
    };

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container justifyContent="flex-end" >
                    <img src={imgMax} alt="img2" height={68} width={43} />
                </Grid>

                <Grid container direction="column" justifyContent="center" alignItems="center">

                    <Grid direction="column" style={webStyles.formContainer} container justifyContent="center" alignItems="center">

                        <Typography variant='h6'>Welcome to ReserveMax.ai..!</Typography>


                        <Box sx={webStyles.selectUser}>
                            {this.RenderChip(this.state.userLenderType, () => this.handleUserSelection(true), 'Lender')}
                            {this.RenderChip(!this.state.userLenderType, () => this.handleUserSelection(false), 'Dealership')}
                        </Box>

                        <Box sx={{ width: "100%" }}>
                            <Formik
                                innerRef={(p) => (this.formikRef = p)}
                                initialValues={{
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    organization_name: '',
                                    job_title: '',
                                    contact_number: '',
                                    number_of_locations: '',
                                    street_address: '',
                                    city: '',
                                    country: 'placeholder',
                                    state: '',
                                    dealership_type: '',
                                    monthly_units_sold: '',
                                    password: '',
                                    terms_condition: false
                                }}
                                validateOnBlur={false}
                                validationSchema={Yup.object().shape({
                                    first_name: Yup.string()
                                        .required("Please enter first name"),
                                    last_name: Yup.string()
                                        .required("Please enter last name"),
                                    email: Yup.string()
                                        .required("Please enter an email")
                                        .email("Please enter valid email address"),
                                    organization_name: Yup.string()
                                        .required("Please enter organization name"),
                                    job_title: Yup.string()
                                        .required("Please enter Job Title"),
                                    contact_number: Yup.string()
                                        .required("Please enter Contact Number").max(10, 'Phone No. must be 10 digit').min(10, 'Phone No. must be 10 digit'),
                                    number_of_locations: this.conditionSort(
                                        !this.state.userLenderType,
                                        Yup.string().required("Please enter number of locations"),
                                        Yup.string()),
                                    street_address: Yup.string()
                                        .required("Please enter street address"),
                                    city: Yup.string()
                                        .required("Please enter city"),
                                    state: Yup.string()
                                        .required("Please enter state"),
                                    country: Yup.string().test('required',
                                        'Please select country', (val) => val !== "placeholder"),
                                    dealership_type: this.conditionSort(
                                        !this.state.userLenderType,
                                        Yup.string().required("Please select dealership type"),
                                        Yup.string()),
                                    monthly_units_sold: this.conditionSort(!this.state.userLenderType, Yup.string()
                                        .required("Please select monthly units sold"),
                                        Yup.string()),
                                    terms_condition: Yup.boolean()
                                        .test('is-true', 'You must accept the privacy policy before continuing.', value => value),
                                    password: Yup.string().required('Password is required').min(8, 'Minimum 8 charcters Required')
                                        .matches(/^(?=.*?[A-Z])(?=.*?[!@#$%^&*])/, 'Password must contain at least one Uppercase letter and one Symbol')
                                })}
                                onSubmit={dataObject => {
                                    this.addDetailsSignUp(dataObject)
                                }}
                                render={({
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    values
                                }: FormikProps<any>) => {
                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                        >
                                            <Box style={webStyles.formWrapper as React.CSSProperties}>
                                                <Box sx={webStyles.customBox}>
                                                    {this.GenInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "First Name", "first_name", "text", "First Name", false, { fn: this.preventEType, rule: /^[A-Za-z]$/ })}
                                                    {this.GenInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "Last Name", "last_name", "text", "Last Name", false, { fn: this.preventEType, rule: /^[A-Za-z]$/ })}
                                                </Box>

                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Email address", "email", "text", "Enter your email address")}

                                                <Box>
                                                    {this.GenInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "Password", "password", this.state.passwordEyeToggleForPassword["password"] ? "text" : "password", "Enter your password", true)}

                                                    {values.password && this.PasswordRequirements(this.requirements, values.password)}
                                                </Box>

                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Organization Name", "organization_name", "text", "Enter your Organization Name")}

                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Job Title", "job_title", "text", "Enter your Job Title")}

                                                {!this.state.userLenderType && this.GenSelectableInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Dealership Type", "dealership_type", ['Auto', 'RV', 'Powersport', 'Marine'])}

                                                {!this.state.userLenderType && this.GenSelectableInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Monthly Units Sold", "monthly_units_sold", ['0-20', '21-49', '50-100', '100+'])}

                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Contact Number", "contact_number", "text", "Enter your Contact number", false, { fn: this.preventEType, rule: /^[^A-Za-z\W_]+$/ })}

                                                {!this.state.userLenderType && this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Number of Locations", "number_of_locations", "text", "Enter number of locations", false, { fn: this.preventEType, rule: /^[^A-Za-z\W_]+$/ })}

                                                <Box sx={webStyles.customBox}>
                                                    {this.GenInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "Street Address", "street_address", "text", "Street address")}
                                                    {this.GenInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "City", "city", "text", "City")}
                                                </Box>

                                                <Box sx={webStyles.customBox}>
                                                    {this.GenInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "State", "state", "text", "Enter State")}
                                                    {this.GenDropDownInput(
                                                        { errors, touched, handleChange, handleBlur, values },
                                                        "country")}
                                                </Box>

                                            </Box>

                                            <Grid direction='row' style={{ marginTop: "49px" }} container justifyContent="space-between" alignItems="center">

                                                {this.GenCheckbox(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    <Typography variant='caption'>I agree with
                                                        <span style={{ color: "#4743E0" }}> terms and conditions.</span>
                                                    </Typography>,
                                                    'terms_condition'
                                                )}

                                                <StyledButton data-test-id="formSubmitBtn" type="submit" variant='contained'>SignUp</StyledButton>
                                            </Grid>

                                        </form>
                                    );
                                }}
                            />
                        </Box>

                        <Typography style={{ marginTop: "22px", color: "#C2C1C0" }} variant='subtitle1'>Already have an account? <span style={{ color: "#9C292D" }}><Link to="/" style={webStyles.linkType}>Login here</Link></span></Typography>

                    </Grid>

                </Grid>

                <ConfirmationModal navigation={"ConfirmationModal"} id={'ConfirmationModal'} isOpen={this.state.modalStats} handleModal={this.handleModal} timer={this.state.timer} />
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    chipRage: {
        height: "40px",
        borderRadius: "33px",
        width: "146px",
        "background": "#F0F0F0"
    },
    activeChipRage: {
        width: "146px",
        borderRadius: "33px",
        color: "#FFF",
        "background": "#CB494C",
        height: "40px"
    },
    inputRage: {
        marginTop: "9px",
        width: "100%",
        height: "54px",
        borderRadius: "40px",
        padding: "19px 30px",
        color: "#ACACAC",
        fontSize: "15px",
        border: "1px solid #9E896A",
        fontWeight: 300
    },
    formContainer: {
        height: "auto",
        maxWidth: "454px"
    },
    formWrapper: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        gap: "17px",
        marginTop: "31px"
    },
    commonErrorMessage: {
        gap: "3px",
        justifyContent: "flex-end",
        display: "flex",
        marginTop: "8px"
    },
    errorMessage: {
        gap: "3px",
        position: "absolute",
        display: "flex",
        left: "-5px"
    },
    eyeIcon: {
        right: "10px",
        position: "absolute",
        top: "36px"
    },
    selectUser: {
        "width": "329px",
        "height": "59px",
        "borderRadius": "33px",
        "background": "#F0F0F0",
        marginTop: "17px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    customBox: {
        width: { xs: "100%", md: "96%" },
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "19px"
    },
    selectInput: {
        borderRadius: "40px",
        height: "54px",
        width: "100%",
    },
    selectableActiveInput: {
        borderRadius: "40px",
        height: "54px",
        width: "100%",
        backgroundColor: "#A7C29A"
    },
    selectInputWrapper: {
        marginTop: "8px",
        display: "flex",
        flexDirection: "row",
        gap: "7px"
    },
    infoMessage: {
        display: "flex",
        gap: "5px",
        marginTop: "8px",
        color: "#79946E"
    },
    passwordErrorMessage: {
        display: "flex",
        gap: "5px",
        marginTop: "8px",
        color: "#ACACAC"
    },
    underlineMessage: {
        textDecorationLine: "underline",
        color: "#79946E",
        display: "flex",
        gap: "5px",
        marginTop: "8px",
    },
    passwordWarning: {
        marginTop: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "3px"
    },
    dropdownRage: {
        marginTop: "9px",
        width: "100%",
        height: "54px",
        borderRadius: "40px",
        paddingLeft: "30px",
        color: "#ACACAC",
        fontSize: "15px",
        fontWeight: 300,
        border: "1px solid #9E896A"
    },
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: "10px"
    },
    linkType: {
        color: 'inherit',
        textDecoration: 'none'
    }
}

const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: '#9E896A' },
        error: { main: "#FF6060" },
    },
    typography: {
        // "fontFamily": `Abadi-MT-Condensed-Extra-Bold-Regular-Font,Rubik Microbe`,
        h6: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 800
        },
        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        caption: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 300
        },
        subtitle1: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        subtitle2: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400
        },
        h5: {
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400
        },
        body2: {
            color: "#ACACAC",
            fontSize: "15px",
            fontWeight: 300,
            fontStyle: "normal"
        }
    },
});

export default WithAuthLayout(EmailAccountRegistration, true)
// Customizable Area End


