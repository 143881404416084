import React from 'react'

// Customizable Area Start
import WithAuthLayout from '../../landingpage/src/CarouselDisplay.web';
import {LoginLenderDealer} from './LoginLenderDealer.web';
// Customizable Area End


// Customizable Area Start

// Customizable Area End

import LoginController, {
    Props
} from "./LoginController";

export class LoginAdmin extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
                <LoginLenderDealer navigation={"LoginLenderDealer"} id={'LoginLenderDealer'} typeAdmin={true} />
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export default WithAuthLayout(LoginAdmin)
// Customizable Area End


