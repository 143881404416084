import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    isOpen: boolean;
    handleModal: any;
    timer: any
    // Customizable Area End
}


interface S {
    // Customizable Area Start

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class ModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    resendConfirmCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {

        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForResendOTPConfirmation(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    normalizeValue(timer: any, max: any) {
        timer = Math.min(max, Math.max(0, timer));
        const elapsedTime = max - timer;
        const normalizedValue = Math.ceil(elapsedTime / max * 100);
        return normalizedValue;
    }

    FormatError = (errors: any[]) => {
        let allerrors = "";
        errors.forEach(object => {
            const newLocal = JSON.stringify(object);
            JSON.parse(newLocal, (key, value) => {
                if (value.length > 0) {
                    allerrors =
                        allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
                }
            });
        });
        return allerrors;
    };

    //API Function
    resendOTPConfirmation = () => {
        const header = {};

        const formData = new FormData();
        formData.append("email", localStorage.getItem('SignUpEmail') as string)

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.resendConfirmCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/accounts/resend_confirmation'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API Response Function
    handleResForResendOTPConfirmation = async (from: string, message: Message) => {
        if (this.resendConfirmCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.message) {
                ToastMessage("success", responseJson.message);
            } else {
                let allerrors = this.FormatError(responseJson.errors);
                ToastMessage("error", allerrors || "Something went Wrong");
            }
        }
    }

    // Customizable Area End

}