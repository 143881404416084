import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    typeAdmin: boolean
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    email: string;
    passwordEyeToggleForPassword: boolean;
    userLenderType: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class LoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailLoginCallIdWeb: string = "";
    formikRef: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            email: '',
            passwordEyeToggleForPassword: true,
            userLenderType: JSON.parse(localStorage.getItem("Rem_Role") as string) || false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForLogin(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    handlePasswordToggle = () => {
        this.setState({
            passwordEyeToggleForPassword: !this.state.passwordEyeToggleForPassword,
        })
    }

    handleUserSelection = (lenderType: boolean) => {
        this.setState({ userLenderType: lenderType }, () => this.formikRef.resetForm())
    }

    FormatError = (errors: any[]) => {
        let allerrors = "";
        errors.forEach(object => {
            const newLocal = JSON.stringify(object);
            JSON.parse(newLocal, (key, value) => {
                if (value.length > 0) {
                    allerrors =
                        allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
                }
            });
        });
        return allerrors;
    };

    RememberMeConditionSort = (Result1: any,Result2: any) => {
        let role = JSON.parse(localStorage.getItem("Rem_Role") as string)
        return role == this.state.userLenderType ? Result1 : Result2;
    }
    //API Function
    doEmailLogIn = (dataObject: any) => {
        if (dataObject.remember_me) {
            localStorage.setItem("Rem_Email", dataObject.email)
            localStorage.setItem("Rem_Password", dataObject.password)
            localStorage.setItem("Rem_Role", JSON.stringify(this.state.userLenderType))
        }
        if (!dataObject.remember_me) {
            localStorage.clear()
        }
        const header = {
            "Content-Type": configJSON.loginApiContentType
        };
        const attrs = {
            email: dataObject.email,
            password: dataObject.password,
            user_type: this.state.userLenderType ? "lender" : "dealer"
        };
        const data = {
            type: "email_account",
            attributes: attrs
        };
        const httpBody = {
            data: data
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiEmailLoginCallIdWeb = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_login/logins'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API Response Function
    handleResForLogin = async (from: string, message: Message) => {
        if (this.apiEmailLoginCallIdWeb === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.meta) {
                ToastMessage("success", "Login Successful")
                localStorage.setItem("authToken", responseJson.meta.token)
                this.props.navigation.navigate("HomeLayout")
            } else {
                let allerrors = this.FormatError(responseJson.errors);
                ToastMessage("error", allerrors || "Something went Wrong");
            }
        }
    }

    // Customizable Area End

}