import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    Grid
} from "@material-ui/core";
import { imgMax, ellipse } from './assets'
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LockIcon from '@material-ui/icons/Lock';
import WithAuthLayout from '../../landingpage/src/CarouselDisplay.web';
// Customizable Area End


// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            textTransform: "none",
            "height": "49px",
            "padding": "6px 10px",
            "borderRadius": "36px",
            "width": "198px",
            fontWeight: 400,
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "14px",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                height: "35px",
                fontSize: "14px",
            }
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import ChangePasswordController, {
    Props
} from "./ChangePasswordController";

export class ForgotPassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    GenInput = (formikProp: any, inputLabel: string, inputName: string, inputType: string, inputPlaceholder: string) => {
        return (
            <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>{inputLabel}</Typography>
                <input
                    placeholder={inputPlaceholder}
                    style={webStyles.inputRage}
                    value={formikProp.values[inputName]}
                    onChange={formikProp.handleChange}
                    type={inputType}
                    name={inputName}
                />


                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Grid data-test-id="errorWrapper" style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-end'>

                        <InfoOutlinedIcon data-test-id="iconeye" color='error' fontSize='small' />

                        <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                            {formikProp.errors[inputName]}
                        </Typography>
                    </Grid>
                }
            </Box>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start


            <ThemeProvider theme={theme}>

                <Grid data-test-id="fp1" container justifyContent="flex-end" >
                    <img src={imgMax} alt="img2" height={68} width={43} />
                </Grid>

                <Grid data-test-id="fp2" container direction="column" justifyContent="center" alignItems="center" >

                    <Grid data-test-id="fp3" direction="column" style={webStyles.formContainer} container justifyContent="center" alignItems="center">

                        <Box data-test-id="fp4" sx={{ position: "relative" }}>
                            <img data-test-id="imgfp" src={ellipse} alt="img2" height={72} width={72} />
                            <LockIcon data-test-id="iconLock" style={webStyles.lockIconRage as React.CSSProperties} color='secondary' />
                        </Box>

                        <Typography style={{ marginTop: "12px" }} variant='h2'>Forgot Password?</Typography>


                        <Typography style={{ marginTop: "12px", color: "#5C5F62" }} variant='body1'>We’ll send new password link to email</Typography>

                        <Box sx={{ width: "100%" }}>
                            <Formik
                                initialValues={{
                                    email: ''
                                }}
                                validateOnBlur={false}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                        .required("Please enter an email")
                                        .email("Please enter valid email address")
                                })}
                                onSubmit={dataObject => {
                                    localStorage.setItem("forgetPasswordEmail",dataObject.email)
                                    this.doEmailVerify(dataObject)
                                }}
                                render={({
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    values
                                }: FormikProps<any>) => {
                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                        >
                                            <Box style={webStyles.formWrapper as React.CSSProperties}>
                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Enter your email address", "email", "text", "Username or email address")}
                                            </Box>

                                            <Grid direction='row' style={{ marginTop: "40px" }} container justifyContent="space-between" alignItems="center">
                                                <Box>
                                                    <Typography style={{ color: "#C2C1C0" }} variant='subtitle1'>
                                                        Do you need help?
                                                    </Typography>
                                                    <Typography style={{ color: "#9C292D" }} variant='subtitle1'>
                                                        Customer support
                                                    </Typography>
                                                </Box>

                                                <StyledButton data-test-id="formSubmitBtn" type="submit" variant='contained'>
                                                    Request password change
                                                </StyledButton>
                                            </Grid>
                                        </form>
                                    );
                                }}
                            />
                        </Box>

                    </Grid>

                </Grid>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    formContainer: {
        maxWidth: "454px",
        height: "auto",
    },
    inputRage: {
        marginTop: "18px",
        width: "100%",
        border: "1px solid #9E896A",
        height: "54px",
        padding: "19px 30px",
        color: "#ACACAC",
        borderRadius: "40px",
        fontSize: "15px",
        fontWeight: 300
    },
    formWrapper: {
        flexDirection: "column",
        display: "flex",
        gap: "23px",
        marginTop: "40px",
        width: "100%",
    },
    lockIconRage: {
        "top": "15px",
        "position": "absolute",
        fontSize: "40px",
        "right": "15px",
    },
}

const theme = createTheme({
    palette: {
        secondary: { main: '#FFF' },
        error: { main: "#FF6060" },
        primary: { main: '#096FFF' },
    },
    typography: {
        subtitle2: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400
        },
        h2: {
            fontSize: "24px",
            fontStyle: 'normal',
            fontWeight: 800
        },
        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        subtitle1: {
            fontSize: "14px",
            fontStyle: 'normal',
            fontWeight: 400
        }
    },
});

export default WithAuthLayout(ForgotPassword)
// Customizable Area End


