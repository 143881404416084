import React from 'react'

// Customizable Area Start
import {
    Box, Typography, Divider, Grid, Select, MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import WithHomeLayout from '../../landingpage/src/WithHomeLayout.web';
import ReactApexChart from 'react-apexcharts'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SquareIcon from '@material-ui/icons/Stop';
// Customizable Area End


// Customizable Area Start

// Customizable Area End

import DocumentstorageController, {
    Props
} from "./DocumentstorageController";

export class Dashboard extends DocumentstorageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    TableHeadLabel = (label: any) => {
        return (<Box>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" } as React.CSSProperties}>
                <Typography variant='h6'>{label}</Typography>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                    <path opacity="0.2" d="M2.4345 4.81539C2.4055 4.78714 2.2815 4.68047 2.1795 4.5811C1.538 3.99854 0.488 2.47881 0.1675 1.68339C0.116 1.56259 0.007 1.25718 0 1.09401C0 0.937652 0.036 0.788602 0.109 0.646371C0.211 0.46907 0.3715 0.326839 0.561 0.248904C0.6925 0.198734 1.086 0.120799 1.093 0.120799C1.5235 0.0428641 2.223 0 2.996 0C3.7325 0 4.4035 0.0428641 4.8405 0.106673C4.8475 0.11398 5.3365 0.191914 5.504 0.277155C5.81 0.433512 6 0.738919 6 1.06576V1.09401C5.9925 1.30687 5.8025 1.75451 5.7955 1.75451C5.4745 2.50706 4.476 3.99172 3.8125 4.58841C3.8125 4.58841 3.642 4.75645 3.5355 4.82952C3.3825 4.9435 3.193 5 3.0035 5C2.792 5 2.595 4.93619 2.4345 4.81539Z" fill="#06152B" />
                </svg>
            </Box>
            <Typography style={{ marginTop: "20px" }} variant='h6'>A34567</Typography>
            <Typography style={{ marginTop: "20px" }} variant='h6'>$A34567</Typography>
            <Typography style={{ marginTop: "20px" }} variant='h6'>A34567</Typography>
            <Typography style={{ marginTop: "20px" }} variant='h6'>A34567</Typography>
            <Typography style={{ marginTop: "20px" }} variant='h6'>A34567</Typography>
        </Box>
        )
    }

    GenLineBar = (title: any, Percentages: any) => {
        return (
            <Box sx={webStyles.linGraphContainer}>
                <Typography variant='caption'>{title}</Typography>
                <Box sx={webStyles.linGraphWrapper}>
                    <Box sx={webStyles.barGraphRage}>
                        <Box width={Percentages} sx={webStyles.progressBar}></Box>
                    </Box>
                    <Typography variant='caption'>{Percentages}</Typography>
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={{ bgcolor: "#F1F4FA", height: "auto", width: "100%" }}>
                    <Grid spacing={2} container direction="row">
                        <Grid item xs={5}>
                            <Box sx={webStyles.donutBox}>
                                <Grid style={{ height: "20%" }} container alignItems="center" justifyContent="space-between">
                                    <Typography variant='body1'>Sales Summary:</Typography>
                                    <Select
                                        style={webStyles.dropdownRage as React.CSSProperties}
                                        autoWidth
                                        disableUnderline
                                        value="placeholder"
                                    >
                                        <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                            All Other Profits
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Divider variant='fullWidth' />

                                <Grid direction="row" container style={{ marginTop: "22px", width: "100%", height: "81%" }}>
                                    <Grid item xs={6}>
                                        <ReactApexChart
                                            width={"100%"}
                                            height={"100%"}
                                            options={options}
                                            series={series}
                                            type="donut" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={webStyles.legendWrapper}>
                                            <Typography align='center' variant='caption'>26% Deals Sent vs 39% approved</Typography>
                                            {ghdata.map(({ label, value, color }) => (
                                                <Box sx={webStyles.legendRage}>
                                                    <Box sx={{ display: "flex", gap: "14px", alignItems: "center" } as React.CSSProperties}>
                                                        <FiberManualRecordIcon style={{ width: "12px", height: "12px", color: color }} />
                                                        <Typography style={{ color: "#615E83" }} variant='h6'>{label}</Typography>
                                                    </Box>
                                                    <Typography style={{ color: "#9291A5" }} variant='h6'>{value}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={webStyles.donutBox}>
                                <Grid style={{ height: "20%" }} container alignItems="center" justifyContent="space-between">
                                    <Typography variant='body1'>Recent Deals</Typography>
                                    <Select
                                        style={webStyles.dropdownRage as React.CSSProperties}
                                        autoWidth
                                        disableUnderline
                                        value="placeholder"
                                    >
                                        <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                            Last 1 month
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Divider variant='fullWidth' />
                                <Box marginTop="17px">
                                    <Box marginBottom={"20px"} sx={webStyles.tableHead}>
                                        {this.TableHeadLabel('Deal ID')}
                                        {this.TableHeadLabel('Reserve Potential')}
                                        {this.TableHeadLabel('Reserve Recieved')}
                                        {this.TableHeadLabel('Product Potential')}
                                        {this.TableHeadLabel('Status')}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid style={{ marginTop: "10px" }} spacing={2} container direction="row">
                        <Grid item xs={6}>
                            <Box sx={webStyles.donutBox}>
                                <Grid style={{ height: "20%" }} container alignItems="center" justifyContent="space-between">
                                    <Typography variant='body1'>Deal Summary & Percentages</Typography>
                                    <Select
                                        style={webStyles.dropdownRage as React.CSSProperties}
                                        autoWidth
                                        disableUnderline
                                        value="placeholder"
                                    >
                                        <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                            Credit Score Widget Planning
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Divider variant='fullWidth' />
                                <Grid direction="row" container style={{ marginTop: "22px", width: "100%", height: "40%" }}>
                                    <Grid item xs={6}>
                                        <Typography variant='h6' style={{ color: "#767676", marginBottom: "20px" }}>Percentages of Deals by Credit Score</Typography>
                                        <Box sx={webStyles.thirdgraphBox}>
                                            <Box sx={webStyles.thirdBoxInnerRage}>
                                                <Box>
                                                    <Typography variant='body1'>756</Typography>
                                                    <Typography variant='caption'>Credit Score</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='body1'>9,421</Typography>
                                                    <Typography variant='caption'>Deals</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={webStyles.graphBox}>
                                                <Box>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" } as React.CSSProperties}>
                                                        <SquareIcon style={{ fontSize: "8px", color: "#C18E49" }} />
                                                        <Typography variant='h5'>70% with 756 credit score</Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px", color: "#9DB890" } as React.CSSProperties}>
                                                        <SquareIcon style={{ fontSize: "8px" }} />
                                                        <Typography variant='h5'>30%</Typography>
                                                    </Box>
                                                </Box>

                                                <ReactApexChart
                                                    width={"70%"}
                                                    height={"70%"}
                                                    options={options2}
                                                    series={[30, 70]}
                                                    type="donut"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h6' style={{ color: "#767676", marginBottom: "20px" }}>Percentages of Deals approved by Credit Score</Typography>
                                        <Box sx={webStyles.thirdgraphBox}>
                                            <Box sx={webStyles.thirdBoxInnerRage}>
                                                <Box>
                                                    <Typography variant='body1'>756</Typography>
                                                    <Typography variant='caption'>Credit Score</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='body1'>9,421</Typography>
                                                    <Typography variant='caption'>Deals</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={webStyles.graphBox}>
                                                <Box>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" } as React.CSSProperties}>
                                                        <SquareIcon style={{ fontSize: "8px", color: "#C18E49" }} />
                                                        <Typography variant='h5'>70% with 756 credit score</Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px", color: "#9DB890" } as React.CSSProperties}>
                                                        <SquareIcon style={{ fontSize: "8px" }} />
                                                        <Typography variant='h5'>30%</Typography>
                                                    </Box>
                                                </Box>

                                                <ReactApexChart
                                                    width={"70%"}
                                                    height={"70%"}
                                                    options={options2}
                                                    series={[30, 70]}
                                                    type="donut"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box sx={webStyles.donutBox}>
                                <Grid style={{ height: "20%" }} container alignItems="center" justifyContent="space-between">
                                    <Typography variant='body1'>Profit Analysis</Typography>
                                    <Select
                                        style={webStyles.dropdownRage as React.CSSProperties}
                                        autoWidth
                                        disableUnderline
                                        value="placeholder"
                                    >
                                        <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                            Total Gross Potential
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Divider variant='fullWidth' />
                                <Box marginTop="22px" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography variant='body1'>Generated sales revenue</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "15px" } as React.CSSProperties}>
                                        <Typography variant='subtitle1'>Year</Typography>
                                        <Typography variant='subtitle1'>Month</Typography>
                                        <Typography variant='subtitle1'>Week</Typography>
                                        <Typography variant='subtitle1'>All Time</Typography>
                                        <Select
                                            style={webStyles.dropdownRage2 as React.CSSProperties}
                                            autoWidth
                                            disableUnderline
                                            value="placeholder"
                                        >
                                            <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                                Bar Graph
                                            </MenuItem>
                                        </Select>
                                    </Box>
                                </Box>
                                <Box>
                                    <ReactApexChart options={BarOptions as any} series={BarSeries} type="bar" height={"210"} />
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>

                    <Grid style={{ marginTop: "10px" }} spacing={2} container direction="row">
                        <Grid item xs={6}>
                            <Box sx={webStyles.donutBox}>
                                <Grid style={{ height: "20%" }} container alignItems="center" justifyContent="space-between">
                                    <Typography variant='body1'>Performance Analysis</Typography>
                                    <Select
                                        style={webStyles.dropdownRage as React.CSSProperties}
                                        autoWidth
                                        disableUnderline
                                        value="placeholder"
                                    >
                                        <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                            Profit by Finance Manager
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Divider variant='fullWidth' />
                                <Box marginTop="22px" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography style={{ color: "#767676" }} variant='h6'>Profit by finance manager</Typography>
                                </Box>
                                <Box>
                                    <ReactApexChart options={BarOptions2 as any} series={BarSeries2} type="bar" height={"210"} />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box sx={webStyles.donutBox}>
                                <Grid style={{ height: "20%" }} container alignItems="center" justifyContent="space-between">
                                    <Typography variant='body1'>Lost Deal Analysis</Typography>
                                    <Select
                                        style={webStyles.dropdownRage as React.CSSProperties}
                                        autoWidth
                                        disableUnderline
                                        value="placeholder"
                                    >
                                        <MenuItem value={"placeholder"} style={{ backgroundColor: "#FFF" }}>
                                            View all
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Divider variant='fullWidth' />

                                <Box sx={webStyles.lineGraphContainer}>
                                    {this.GenLineBar('Deals Captured', '62%')}
                                    {this.GenLineBar('Deals Lost', '18%')}
                                    {this.GenLineBar('Lost to competitor ', '5%')}
                                    {this.GenLineBar('Lost to Cash Deal', '12%')}
                                    {this.GenLineBar('Unable to Finance', '3%')}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    lineGraphContainer: {
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    linGraphWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px"
    },
    progressBar: {
        bgcolor: "#89A17F",
        height: "9px",
        borderRadius: "0 4px 4px 0"
    },
    barGraphRage: {
        bgcolor: "#F1F4FA",
        width: "100%",
        height: "9px",
        borderRadius: "4px",
        overflow: "hidden"
    },
    linGraphContainer: {
        display: "flex",
        flexDirection: "column",
    },
    thirdBoxInnerRage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "0 24px"
    },
    graphBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    thirdgraphBox: {
        "width": "242px",
        "height": "174px",
        "borderRadius": "5px",
        "border": "2px solid #F8F8F8",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    donutBox: {
        "width": "100%",
        "height": "341px",
        "borderRadius": "18px",
        "bgcolor": "white",
        "boxShadow": "0px 1.7839194536209106px 5.351758003234863px 0px rgba(13, 10, 44, 0.08)",
        boxSizing: "border-box",
        padding: "0px 18px 18px 18px"
    },
    dropdownRage: {
        "width": "213px",
        "height": "40px",
        "borderRadius": "33px",
        "border": "2px solid #F8F8F8",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "400"
    },
    dropdownRage2: {
        "width": "110px",
        "height": "40px",
        "borderRadius": "33px",
        "border": "2px solid #F8F8F8",
        "background": "#F8F8F8",
        "fontSize": "10px",
        "fontStyle": "normal",
        "fontWeight": "400"
    },
    legendRage: {
        width: "188px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    legendWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "25px",
        alignItems: "center"
    },
    tableHead: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    }
}

const theme = createTheme({
    palette: {
        secondary: { main: '#565656' },
        error: { main: "#FF6060" },
        primary: { main: '#096FFF' },
    },
    typography: {
        body1: {
            fontSize: "16px",
            fontStyle: 'normal',
            fontWeight: 600,
        },
        caption: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 600
        },
        h3: {
            fontSize: "18px",
            fontStyle: 'normal',
            fontWeight: 400,
        },
        h6: {
            fontSize: "14px",
            fontStyle: 'normal',
            fontWeight: 400,
        },
        h5: {
            fontSize: "8px",
            fontStyle: 'normal',
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: "10px",
            fontStyle: 'normal',
            fontWeight: 600,
        }
    },
});

const ghdata = [
    { label: "Approved", value: 44, color: "#FFB951" },
    { label: "Declined", value: 55, color: "#79946E" },
    { label: "Submitted", value: 41, color: '#D5D5D5' },
    { label: "Sent", value: 17, color: "#EE3E49" },
]

const series = ghdata.map(item => item.value)
const options = {
    labels: ghdata.map(item => item.label),
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                // width: 
            },
            legend: {
                position: 'bottom'
            },

        }
    }],
    plotOptions: {
        pie: {
            donut: {
                labels: {
                    show: true,
                    total: {
                        show: true,
                        label: "Total Gross Sales:",
                        showAlways: true,
                        fontSize: "8.498px",
                        fontWeight: 400
                    },
                    value: {
                        show: true,
                        fontSize: '16px',
                        fontWeight: 700,
                        offsetY: 0
                    },
                }
            }
        }
    },
    legend: {
        show: false
    },
    colors: ['#FFB951', '#79946E', '#D5D5D5', '#EE3E49'],
    states: {
        hover: {
            filter: {
                type: 'darken',
                value: 0.75,
            },
        },
    },
}

const options2 = {
    labels: ghdata.slice(0, 2).map(item => item.label),
    legend: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
    colors: ['#9DB890', '#C18E49'],
}

const BarOptions = {
    chart: {
        type: 'bar',
        toolbar: {
            show: false
        }
    },
    colors: ['#FB6C6A'],
    plotOptions: {
        bar: {
            borderRadius: 7,
            borderRadiusApplication: 'end',
            columnWidth: '30%',
            // distributed: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May',
            'Jun', 'Jul', 'Aug', 'Sep', 'Oct'
        ],
        labels: {
            style: {
                fontSize: '14px',
                fontWeight: 400
            }
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false
        }
    },
    yaxis: {
        labels: {
            formatter: function (value: any) {
                return "$" + value + "k";
            },
            style: {
                fontSize: '14px',
                fontWeight: 400
            }
        },
    },
    grid: {
        xaxis: {
            lines: {
                show: false,
            }
        },
        yaxis: {
            lines: {
                show: false,
            }
        }
    }
}

const BarSeries = [{
    data: [21, 22, 10, 28, 16, 21, 13, 30, 56, 56]
}]

const BarOptions2 = {
    chart: {
        type: 'bar',
        toolbar: {
            show: false
        }
    },
    colors: ['#FB6C6A'],
    plotOptions: {
        bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            columnWidth: '45%',
            // distributed: true,
        }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val: any) {
            return val + "%";
        }
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: [
            'Full Name', 'Full Name', 'Full Name', 'Full Name', 'Full Name',
            'Full Name', 'Full Name'
        ],
        labels: {
            style: {
                "color": "#8D8D8E",
                "fontSize": "8px",
                "fontWeight": "400"
            }
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false
        }
    },
    yaxis: {
        labels: {
            formatter: function (value: any) {
                return "$" + value + "M";
            },
            style: {
                fontSize: '14px',
                fontWeight: 400
            }
        },
    },
    grid: {
        strokeDashArray: 4,
        borderColor: '#999999',
        xaxis: {
            lines: {
                show: false,
            }
        },
        yaxis: {
            lines: {
                show: true,
            }
        }
    }
}

const BarSeries2 = [{
    data: [1, 2, 3, 3.5, 2.6, 1.4, 2.2]
}]
export default WithHomeLayout(Dashboard)
// Customizable Area End


