import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIndex: any;
  otpValue: any;
  otpError: boolean;
  passwordEyeToggleForPassword: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailVerifyCallId: string = "";
  apiOTPVerifyCallId: string = "";
  changePasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      activeIndex: null,
      otpValue: "",
      otpError: false,
      passwordEyeToggleForPassword: {
        confirm_password: false,
        new_password: false
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForEmailVerify(from, message)
    this.handleResForOTPVerify(from, message)
    this.handleResForChangePasword(from, message)
    // Customizable Area End
  }

  // Customizable Area Start

  //Web logic Function
  handleChangeOTP = (value: any) => {
    this.setState({
      otpValue: value,
      otpError: false
    });
  };

  handlePasswordToggle = (inputName: string) => {
    this.setState({
      passwordEyeToggleForPassword: { ...this.state.passwordEyeToggleForPassword, [inputName]: !this.state.passwordEyeToggleForPassword[inputName] }
    })
  }

  //API Function
  doEmailVerify = (dataObject: any) => {
    localStorage.setItem("forgetPasswordEmail", dataObject.email)
    let email = dataObject.email
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const attrs = {
      email: email,
    };
    const data = {
      attributes: attrs
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailVerifyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otps'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  doOTPVerify = () => {
    if (this.state.otpValue.length !== 6) {
      return this.setState({ otpError: true })
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: localStorage.getItem("ForgetPasswordToken"),
    };

    const httpBody = {
      otp_code: this.state.otpValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiOTPVerifyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/otp_confirmations`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changePassword = (dataObject: any) => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: localStorage.getItem("ForgetPasswordToken"),
    };

    const httpBody = {
      "new_password": dataObject.new_password,
      "password_confirmation": dataObject.confirm_password
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/password'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  FormatError = (errors: any[]) => {
    let allerrors = "";
    errors.forEach(object => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          allerrors =
            allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
        }
      });
    });
    return allerrors;
  };

  //API Response Function
  handleResForEmailVerify = async (from: string, message: Message) => {
    if (this.emailVerifyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.meta) {
        localStorage.setItem("ForgetPasswordToken", responseJson.meta.token)
        ToastMessage("success", "OTP sent to your email")
        this.props.navigation.navigate("ForgotPasswordOTP")
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForOTPVerify = async (from: string, message: Message) => {
    if (this.apiOTPVerifyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.messages) {
        ToastMessage("success", "Account Verified")
        this.props.navigation.navigate("NewPassword")
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForChangePasword = async (from: string, message: Message) => {
    if (this.changePasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.account) {
        localStorage.clear()
        ToastMessage("success", responseJson.message)
        setTimeout(() => {
          this.props.navigation.navigate("Home")
        }, 1000);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  // Customizable Area End
}
