import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import MailIcon from '@material-ui/icons/Mail';
import { imgMax, ellipse } from './assets'
import OtpInput from "react-otp-input";
import WithAuthLayout from '../../landingpage/src/CarouselDisplay.web';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// Customizable Area End


// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            "width": "198px",
            "borderRadius": "36px",
            "height": "49px",
            fontWeight: 400,
            textTransform: "none",
            "padding": "6px 10px",
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "14px",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                height: "35px",
                fontSize: "14px",
            }
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            color: "#9C292D",
            minWidth: "0px",
            fontSize: "14px",
            fontStyle: 'normal',
            fontWeight: 400,
            textTransform: "none",
            "padding": "0px",
            '&:hover': {
                opacity: "0.7"
            }
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import ChangePasswordController, {
    Props
} from "./ChangePasswordController";

export class ForgotPasswordOTP extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid data-test-id="img" container justifyContent="flex-end">
                    <img src={imgMax} alt="img2" height={68} width={43} />
                </Grid>

                <Grid data-test-id="cx1" container direction="column" justifyContent="center" alignItems="center">

                    <Grid data-test-id="cx3" direction="column" style={webStyles.formContainer} container justifyContent="center" alignItems="center">
                        <Box data-test-id="cx4" sx={{ position: "relative" }}>
                            <img src={ellipse} alt="img2" height={72} width={72} />
                            <MailIcon style={webStyles.lockIconRage as React.CSSProperties} color='secondary' />
                        </Box>
                        <Typography style={{ marginTop: "12px" }} variant='h2'>Verify email address</Typography>
                        <Typography style={{ marginTop: "12px", color: "#5C5F62" }} variant='body1'>
                            Enter OTP send to your email
                        </Typography>

                        <Box sx={{ width: "100%", marginTop: "45px" }}>
                            <Box>
                                <OtpInput
                                    shouldAutoFocus
                                    data-test-id="otpInput"
                                    value={this.state.otpValue}
                                    onChange={this.handleChangeOTP}
                                    numInputs={6}
                                    inputStyle={webStyles.otpInput}
                                    containerStyle={webStyles.otpContainer}
                                    isInputNum={true}
                                    placeholder={"------"}
                                />
                                {this.state.otpError && <Grid style={{ marginTop: "3px" }} container alignItems='center' justifyContent='flex-end'>
                                    <InfoOutlinedIcon color='error' fontSize='small' />
                                    <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                                        Please enter valid OTP
                                    </Typography>
                                </Grid>}
                            </Box>

                            <Grid direction='row' style={{ marginTop: "40px" }} container justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Typography style={{ color: "#C2C1C0" }} variant='subtitle1'>
                                        Still not received?
                                    </Typography>
                                    <StyledButton2 data-test-id="resendOTPBtn" onClick={() => this.doEmailVerify({ email: localStorage.getItem("forgetPasswordEmail") })} variant='text'>
                                        Resend
                                    </StyledButton2>
                                </Box>

                                <StyledButton data-test-id="submitBtn" onClick={this.doOTPVerify} variant='contained'>
                                    Verify Now
                                </StyledButton>
                            </Grid>
                        </Box>

                    </Grid>

                </Grid>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    formContainer: {
        height: "auto",
        maxWidth: "454px"
    },
    otpContainer: {
        justifyContent: "space-between"
    },
    otpInput: {
        "width": "80%",
        "height": "57px",
        "borderRadius": "9px",
        "border": "1px solid #A7C29A",
        "background": "#FFF",
        "padding": "19px 25px",
        "fontSize": "16px",
        "fontWeight": "500"
    },
    lockIconRage: {
        "position": "absolute",
        "top": "15px",
        "right": "15px",
        fontSize: "40px"
    }
}

const theme = createTheme({
    palette: {
        error: { main: "#FF6060" },
        secondary: { main: '#FFF' },
        primary: { main: '#096FFF' },
    },
    typography: {
        subtitle1: {
            fontSize: "14px",
            fontStyle: 'normal',
            fontWeight: 400
        },
        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        subtitle2: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400
        },
        h2: {
            fontSize: "24px",
            fontStyle: 'normal',
            fontWeight: 800
        },
        h6: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 800
        }
    },
});

export default WithAuthLayout(ForgotPasswordOTP)
// Customizable Area End