import React from "react";

// Customizable Area Start
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { slider1 } from "./assets";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// Customizable Area End

// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      textTransform: "none",
      "height": "63px",
      "padding": "6px 10px",
      "borderRadius": "36px",
      "width": "251px",
      fontWeight: 400,
      "background": "#FFF",
      color: "#C9262D;",
      fontSize: "17px",
      '&:hover': {
        opacity: "0.7",
      },
      [theme.breakpoints.down('xs')]: {
        width: "120px",
        height: "35px",
        fontSize: "14px",
      }
    },
  }),
)((props: any) => <Button {...props} />);
// Customizable Area End

const WithAuthLayout = (WrappedComponent: any, banner?: boolean, typeLogin?: boolean) => {
  return class CarouselDisplay extends React.Component {
    interval: any;
    constructor(props: any) {
      super(props);
    }

    state = {
      timer: ['00', '00', '00']
    };

    formatTime = (totalSeconds: number) => {
      const hrs = Math.floor(totalSeconds / 3600);
      const mins = Math.floor((totalSeconds % 3600) / 60);
      const secs = totalSeconds % 60;

      return [`${hrs.toString().padStart(2, '0')}`, `${mins.toString().padStart(2, '0')}`, `${secs.toString().padStart(2, '0')}`];
    };

    startTimer(hours: number) {
      const initialTimeInSeconds = hours * 3600;

      let timeInSeconds = parseInt(localStorage.getItem('timer') as string) || initialTimeInSeconds;

      this.interval = setInterval(() => {
        if (timeInSeconds === 0) {
          clearInterval(this.interval);
          return;
        }
        timeInSeconds--;
        localStorage.setItem('timer', timeInSeconds.toString());
        let formatedTime = this.formatTime(timeInSeconds)
        this.setState({ timer: formatedTime })
      }, 1000);

    }

    goToRegisterPage = () => {
      // @ts-ignore
      this.props.navigation.navigate("EmailAccountRegistration")
    }

    componentWillUnmount() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }

    componentDidMount() {
      this.startTimer(48)
    }

    render() {
      return (
        <ThemeProvider theme={theme}>
          <Box sx={webStyles.root}>
            <Grid container spacing={1}>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <Carousel
                  data-test-id="carousel"
                  autoPlay
                  infiniteLoop
                  interval={3000}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  renderIndicator={(onClickHandler, isSelected, index) => {
                    const customClass = !isSelected ? webStyles.indicatorIcon : webStyles.activeIndicatorIcon;
                    return (
                      <div style={customClass as React.CSSProperties} onClick={onClickHandler} key={index} />
                    );
                  }}
                >
                  {[1, 2, 3].map(() => (
                    <Box sx={webStyles.imgWrapper}>
                      <img
                        style={webStyles.imageRage as React.CSSProperties}
                        src={slider1}
                        alt="img"
                      />
                      <Box sx={webStyles.imgTextContainer}>
                        <Typography align="left" variant="h1" color="secondary">
                          Lorem Ipsum is simply
                        </Typography>
                        <Typography align="left" variant="h4" color="secondary">
                          Lorem Ipsum is simply
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Carousel>

                {banner && <Box sx={webStyles.bannerWrapper}>

                  <Typography align="center" variant="h2" color="secondary">
                    Complete Registration to receive a Bonus
                  </Typography>

                  <Box sx={webStyles.timerWrapper}>
                    <fieldset style={webStyles.offerTimeContainer}>
                      <legend style={{ textAlign: "center" }}> <Typography variant="caption" color="secondary">
                        OFFER ENDS IN
                      </Typography>
                      </legend>
                      <Box sx={webStyles.offerTimeContainer}>
                        {['HRS', 'MIN', 'SEC'].map((timeLabel, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <Typography component={'span'} style={{ margin: "0 5px" }} color="secondary" variant="subtitle1">:</Typography>}
                            <Typography component={'span'} color="secondary" variant="subtitle1">
                              {this.state.timer[index]}
                              <Typography component={'span'} color="secondary" variant="subtitle2">
                                {timeLabel}
                              </Typography>
                            </Typography>
                          </React.Fragment>
                        ))}

                      </Box>
                    </fieldset>
                    {typeLogin && <StyledButton data-test-id="registerBtn" onClick={this.goToRegisterPage} variant='contained'>
                      Register
                    </StyledButton>}
                  </Box>

                </Box>}
              </Grid>

              <Grid item lg={6} sm={12} xs={12} md={6}>
                <Box sx={webStyles.pageLayout}>
                  <WrappedComponent {...this.props} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      );
    }
  };
};

// Customizable Area Start
const webStyles = {
  root: {
    padding: { lg: "38px 48px", xs: "0px", md: "38px 48px" },
    overflowX:"hidden"
  },
  Icon: {
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    backgroundColor: "#E7E7E7"
  },
  imageRage: {
    width: "100%",
    height: "auto"
  },
  imgTextContainer: {
    maxWidth: { xs: "90%", sm: "62%" },
    position: "absolute",
    top: "32px",
    left: "40px"
  },
  pageLayout: {
    position: { xs: "absolute", md: "initial" },
    top: { xs: "340px", sm: "480px", md: "0" },
    padding: { xs: "10px", md: "0px" },
    borderRadius: { xs: "34px 34px 0 0", md: "0px" },
    bgcolor: "#fff",
    width: "100%",
    boxSizing: "border-box"
  },
  indicatorIcon: {
    "width": "13px",
    "height": "13px",
    "borderRadius": "11px",
    "background": "#E7E7E7"
  },
  activeIndicatorIcon: {
    "width": "32px",
    "height": "13px",
    "borderRadius": "11px",
    "background": "#FFF"
  },
  imgWrapper: {
    position: "relative",
    overflow: "auto",
    borderRadius: { xs: "0px", md: "40px" },
  },
  bannerWrapper: {
    "borderRadius": "20px",
    "background": "linear-gradient(223deg, #EE3E49 0%, #A3282D 100%)",
    "height": "255px",
    marginTop: "21px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-evenly",
    flexDirection: "column"
  },
  linkType: {
    color: 'inherit',
    textDecoration: 'none'
  },
  timerWrapper: {
    "display": "flex",
    "alignItems": "center",
    "width": "100%",
    "justifyContent": "space-evenly",
  },
  offerTimeContainer: {
    maxWidth: "278px",
    maxHeight: "68px"
  }
};

const theme = createTheme({
  palette: {
    primary: { main: "#096FFF" },
    secondary: { main: "#FFF" }
  },
  typography: {
    h1: {
      fontSize: "37px",
      fontWeight: 800,
      fontStyle: "normal"
    },
    h2: {
      fontSize: "28px",
      fontWeight: 400,
      fontStyle: "normal"
    },
    h4: {
      fontSize: "25px",
      fontWeight: 400,
      fontStyle: "normal"
    },
    caption: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "normal"
    },
    subtitle1: {
      fontSize: "32px",
      fontWeight: 700,
      fontStyle: "normal"
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 700,
      fontStyle: "normal"
    }
  }
});

export default WithAuthLayout;
// Customizable Area End
