import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    Grid, CircularProgress, FormControlLabel, Checkbox, Select, MenuItem, IconButton, Dialog
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import OtpInput from "react-otp-input";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End


// Customizable Area Start
export const CustomDialog: any = withStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: "40px",
            background: 'rgba(255, 255, 255, 0.81)'
            // opacity: "0.7"
        }
    }),
)((props: any) => <Dialog {...props} />);

export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            "width": "198px",
            "borderRadius": "36px",
            "height": "49px",
            fontWeight: 400,
            textTransform: "none",
            "padding": "6px 10px",
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "14px",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                height: "35px",
                fontSize: "14px",
            }
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            color: "#9C292D",
            minWidth: "0px",
            fontSize: "14px",
            fontStyle: 'normal',
            fontWeight: 400,
            textTransform: "none",
            "padding": "0px",
            '&:hover': {
                opacity: "0.7"
            }
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import Settings2Controller, {
    Props,
} from "./Settings2Controller";

class SettingsModal extends Settings2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    GenDeleteConfirmationModal = () => {
        return (
            <>
                <Typography style={{ color: "#42505C" }} gutterBottom align='center' variant='h1'>Delete Account?</Typography>

                <Typography style={{ color: "#404040" }} gutterBottom align='center' variant='body2'>
                    You’ll permanently loose your profile.
                </Typography>

                <Box style={webStyles.actionBtnWrapper}>

                    <Button onClick={() => this.props.handleModal(false)} style={webStyles.cancelBtn as React.CSSProperties} variant="outlined">Cancel</Button>

                    <Button onClick={this.props.parentFun.deleteAccount} style={webStyles.delAccBtn as React.CSSProperties} variant="contained" color="primary">Delete Account</Button>

                </Box>
            </>
        )
    }

    GenAskVerification = () => {
        return (
            <>
                <Typography style={{ color: "#42505C" }} gutterBottom align='center' variant='h1'>Verify email address</Typography>

                <Typography style={{ color: "#404040" }} gutterBottom align='center' variant='h5'>
                    {`We'll need to verify your email address, ${localStorage.getItem('newEmail')}  in order to change it.`}
                </Typography>

                <Box style={webStyles.actionBtnWrapper2 as React.CSSProperties}>

                    <Button onClick={this.props.parentFun.emailVerify} style={webStyles.sendOTPBtn as React.CSSProperties} variant="contained" color="primary">Send Verification Code</Button>

                    <Button style={webStyles.cancelBtn1 as React.CSSProperties} onClick={() => this.props.handleModal(false)} variant="text">Cancel</Button>

                </Box>

            </>
        )
    }

    GenOTPInputPage = () => {
        return (
            <>
                <Typography style={{ color: "#42505C" }} gutterBottom align='center' variant='h1'>Enter verification code</Typography>

                <Typography style={{ color: "#404040" }} gutterBottom align='center' variant='h5'>
                    {`We've sent verification code to ${localStorage.getItem('newEmail')} in order to change your email.`}
                </Typography>

                <Box sx={{ width: "100%", marginTop: "45px" }}>
                    <Box>
                        <OtpInput
                            shouldAutoFocus
                            data-test-id="otpInput"
                            value={this.props.parentFun.state.otpValue}
                            onChange={this.props.parentFun.handleChangeOTP}
                            numInputs={6}
                            inputStyle={webStyles.otpInput}
                            containerStyle={webStyles.otpContainer}
                            isInputNum={true}
                            placeholder={"------"}
                        />

                        {this.props.parentFun.state.otpError && <Grid style={{ marginTop: "3px" }} container alignItems='center' justifyContent='flex-end'>
                            <InfoOutlinedIcon color='error' fontSize='small' />
                            <Typography color='error' variant='body2' style={{ marginLeft: "3px" }}>
                                Please enter valid OTP
                            </Typography>
                        </Grid>}

                    </Box>

                    <Grid direction='row' style={{ marginTop: "40px" }} container justifyContent="space-between" alignItems="center">
                        <Box>
                            <Typography style={{ color: "#C2C1C0" }} variant='body1'>
                                Still not received?
                            </Typography>
                            <StyledButton2 onClick={this.props.parentFun.emailVerify} data-test-id="resendOTPBtn" variant='text'>
                                Resend
                            </StyledButton2>
                        </Box>

                        <StyledButton onClick={this.props.parentFun.verifyOTP} data-test-id="submitBtn" variant='contained'>
                            Verify Now
                        </StyledButton>
                    </Grid>
                </Box>
            </>
        )
    }

    GenEmailInputPage = () => {
        return (<>
            <Typography style={{ color: "#42505C" }} gutterBottom align='center' variant='h1'>Change your email</Typography>

            <Box sx={{ width: "100%" }}>
                <Formik
                    initialValues={{
                        new_email: localStorage.getItem('newEmail'),
                        confirm_email: ''
                    }}
                    validateOnBlur={false}
                    validationSchema={Yup.object().shape({
                        new_email: Yup.string()
                            .required("Please enter an email")
                            .email("Please enter valid email address"),
                        confirm_email: Yup.string()
                            .required('Please retype your Email.')
                            .oneOf([Yup.ref('new_email')], 'Your email do not match.')
                    })}
                    onSubmit={dataObject => {
                        this.props.parentFun.updateEmail(dataObject)
                    }}
                    render={({
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values
                    }: FormikProps<any>) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                            >
                                <Box style={webStyles.formWrapper as React.CSSProperties}>
                                    {this.GenInput(
                                        { errors, touched, handleChange, handleBlur, values },
                                        "New Email", "new_email", "text", "Enter your Email")}
                                    {this.GenInput(
                                        { errors, touched, handleChange, handleBlur, values },
                                        "Confirm New email", "confirm_email", "text", "Enter your Email")}
                                </Box>

                                <Grid direction='row' style={{ marginTop: "40px" }} container justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Typography style={{ color: "#ACACAC" }} variant='subtitle1'>
                                            Do you need help?
                                        </Typography>
                                        <Typography style={{ color: "#9C292D" }} variant='subtitle1'>
                                            Customer support
                                        </Typography>
                                    </Box>

                                    <StyledButton data-test-id="formSubmitBtn" type="submit" variant='contained'>
                                        Change email
                                    </StyledButton>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Box>
        </>)
    }

    GenInput = (formikProp: any, inputLabel: string, inputName: string, inputType: string, inputPlaceholder: string) => {
        return (
            <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>{inputLabel}</Typography>
                <input
                    placeholder={inputPlaceholder}
                    style={webStyles.inputRage}
                    value={formikProp.values[inputName]}
                    onChange={formikProp.handleChange}
                    type={inputType}
                    name={inputName}
                />


                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Grid data-test-id="errorWrapper" style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-end'>

                        <InfoOutlinedIcon data-test-id="iconeye" color='error' fontSize='small' />

                        <Typography color='error' variant='body2' style={{ marginLeft: "3px" }}>
                            {formikProp.errors[inputName]}
                        </Typography>
                    </Grid>
                }
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <CustomDialog
                    fullWidth
                    maxWidth="sm"
                    open={this.props.isOpen}
                    onClose={() => this.props.handleModal(false)}
                >

                    <ToastContainer
                        position="top-center"
                        autoClose={1500}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        rtl={false}
                    />

                    <Box sx={webStyles.dialogWrapper}>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                            <IconButton onClick={() => this.props.handleModal(false)} data-test-id='closeBtn'>
                                <CancelIcon color="secondary" />
                            </IconButton>
                        </Grid>

                        <this.ConditionalPanel conditionArray={[this.props.type == 'emailInput']}>
                            {this.GenEmailInputPage()}
                        </this.ConditionalPanel>

                        <this.ConditionalPanel conditionArray={[this.props.type == 'askEmailVerification']}>
                            {this.GenAskVerification()}
                        </this.ConditionalPanel>

                        <this.ConditionalPanel conditionArray={[this.props.type == 'sendOTPPage']}>
                            {this.GenOTPInputPage()}
                        </this.ConditionalPanel>

                        <this.ConditionalPanel conditionArray={[this.props.type == 'deleteAccModal']}>
                            {this.GenDeleteConfirmationModal()}
                        </this.ConditionalPanel>
                    </Box>

                </CustomDialog>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    dialogWrapper: {
        boxSizing: "border-box",
        padding: "24px 30px"
    },
    delAccBtn: {
        "borderRadius": "36px",
        "background": "#9C292D",
        width: "40%",
        height: '51px'
    },
    cancelBtn: {
        "borderRadius": "26px",
        "border": "1px solid #C5C5C5",
        width: "40%",
        height: '51px'
    },
    actionBtnWrapper: {
        margin: "20px 0px",
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly"
    },
    actionBtnWrapper2: {
        margin: "20px 0px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        gap: "15px"
    },
    sendOTPBtn: {
        "borderRadius": "36px",
        "background": "#9C292D",
        width: "fit-content",
        height: '51px',
        alignSelf: 'center'
    },
    cancelBtn1: {
        width: "fit-content",
        height: '51px',
        alignSelf: 'center'
    },
    otpContainer: {
        justifyContent: "space-between"
    },
    otpInput: {
        "width": "80%",
        "height": "57px",
        "borderRadius": "9px",
        "border": "1px solid #A7C29A",
        "background": "#FFF",
        "padding": "19px 25px",
        "fontSize": "16px",
        "fontWeight": "500"
    },
    formWrapper: {
        flexDirection: "column",
        display: "flex",
        gap: "23px",
        marginTop: "40px",
        width: "100%",
    },
    inputRage: {
        marginTop: "18px",
        width: "100%",
        border: "1px solid #9E896A",
        height: "54px",
        padding: "19px 30px",
        color: "#ACACAC",
        borderRadius: "40px",
        fontSize: "15px",
        fontWeight: 300
    },
}

const theme = createTheme({
    palette: {
        primary: { main: '#9C292D' },
        secondary: { main: '#E8C4C6' },
        error: { main: "#FF6060" },
    },
    typography: {
        h1: {
            fontSize: '40px',
            fontStyle: 'normal',
            fontWeight: 400,
        },
        body1: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
        },
        body2: {
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
        },
        h5: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
        }
    },
});

export default SettingsModal
// Customizable Area End


