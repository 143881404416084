import { Route, Redirect, withRouter } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

const isAuthenticated = () => {
  const token = localStorage.getItem('authToken');
  return token !== null; // Return true if token is present, false otherwise
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Redirect to="/HomeLayout" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

function Wrapper({ element, history, match, routeMap, closeModal, activeRoute }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal,
    activeRoute,
    history
  });
}

const WrappedWrapper = withRouter(Wrapper);

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
  activeRoute: PropTypes.string
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      // Use ProtectedRoute for protected routes
      return currentRoute.protected ? (
        <ProtectedRoute
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          component={props => (
            <WrappedWrapper activeRoute={currentRoute.path} element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      ) : (
        <AuthRoute
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          component={props => (
            <Wrapper activeRoute={currentRoute.path} element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
