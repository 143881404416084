import React from 'react'

// Customizable Area Start
import {
    Button,
    Box, Typography, Avatar, Divider, Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import WithHomeLayout from '../../landingpage/src/WithHomeLayout.web';
import { profileImg } from "./assets";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import SettingsModal from './SettingsModal.web'
// Customizable Area End


// Customizable Area Start
export const GenerateStyledButton = (customStyles: any, variant: any, label: any, fnObj?: any) => {
    const StyledButton = withStyles((theme: Theme) =>
        createStyles({
            root: {
                ...customStyles
            },
        }),
    )((props) => <Button onClick={() => fnObj.fun(fnObj.argument)} variant={variant} {...props} />);
    return (<StyledButton>{label}</StyledButton>)
};
// Customizable Area End

import Settings2Controller, {
    Props,
} from "./Settings2Controller";

export class Settings extends Settings2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    GenInput = (inputProps: any, containerStyle: any, inputStyle: any, textColor?: any, validateValue?: any, formikProp?: any, inputSize?: any) => {
        return (
            <Box sx={containerStyle}>
                <Typography color={textColor} variant='body1'>{inputProps.inputLabel}</Typography>

                <Box width={inputSize}>
                    <input
                        style={inputStyle}
                        ref={(ref) => (this.inputRefs[inputProps.inputName] = ref)}
                        name={inputProps.inputName}
                        disabled={inputProps.enableInput ? !inputProps.enableInput : this.conditionSort(inputProps.inputName == this.state.enableEdit, false, true)}
                        onKeyDown={(e) => inputProps.keyRule ? inputProps.keyRule.fn(e, inputProps.keyRule.rule) : null}
                        value={formikProp.values[inputProps.inputName]}
                        onChange={formikProp.handleChange}
                    />

                    {formikProp.touched[inputProps.inputName] && formikProp.errors[inputProps.inputName] &&
                        <Grid data-test-id="np" style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-start'>
                            <InfoOutlinedIcon data-test-id="npx" color='error' fontSize='small' />

                            <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                                {formikProp.errors[inputProps.inputName]}
                            </Typography>

                        </Grid>
                    }

                    {validateValue && this.PasswordRequirements(this.requirements, validateValue)}
                </Box>
            </Box>
        )
    }

    GenEditBtn = (inputProps: any) => {
        {
            return (<>
                <Typography color="secondary" variant='h3'>ㅤ</Typography>
                <Button onClick={() => this.handleEditBtn(inputProps.inputName, inputProps.formikProp.handleSubmit)} variant='outlined' style={webStyles.editBtn as React.CSSProperties}>
                    {this.conditionSort(inputProps.formikProp.values[inputProps.inputName] || inputProps.inputName == this.state.enableEdit, this.conditionSort(inputProps.inputName == this.state.enableEdit, 'Save', 'Edit'), 'Add')}
                </Button>
            </>)
        }
    }

    Genlayout = (input1Props: any, input2Props: any) => {
        return (
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={5}>
                        {this.GenInput(input1Props, {}, webStyles.inputRage, 'secondary', '', input1Props.formikProp, '100%')}
                    </Grid>
                    <Grid item xs={5}>
                        {this.GenInput(input2Props, {}, webStyles.inputRage, 'secondary', '', input2Props.formikProp, '100%')}
                    </Grid>
                    <Grid item xs={1}>
                        {this.GenEditBtn(input2Props)}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    PasswordRequirements = (requirements: any, newPassword: any) => {

        const validationResults = requirements.map((data: any) => data.regex.test(newPassword));

        let status = requirements.map((data: any, index: any) => {
            const Icon = !validationResults[index] ? InfoOutlinedIcon : CheckCircleIcon;
            const style = validationResults[index] ? webStyles.infoMessage : webStyles.passwordErrorMessage;

            return (
                <Typography data-test-id="t1" style={style} variant='subtitle2' key={index}>
                    <span><Icon color='error' fontSize='small' /></span>
                    {data.message}
                </Typography>
            );
        });

        return (
            <Box data-test-id="t2" sx={webStyles.passwordWarning}>
                <Typography data-test-id="t1" style={webStyles.underlineMessage} variant='subtitle2'>
                    <span><InfoOutlinedIcon data-test-id="iconX" color='error' fontSize='small' /></span>
                    Password Requirements:
                </Typography>
                {status}
            </Box>
        );
    };

    requirements = [
        { regex: /.{8,}/, message: "At least 8 characters long" },
        { regex: /[A-Z]/, message: "At least 1 uppercase character" },
        { regex: /[a-z]/, message: "At least 1 lowercase character" },
        { regex: /\d/, message: "At least 1 number" },
        { regex: /[!@#$%^&*()\-_=+{};:,<.>]/, message: "At least 1 special character" }
    ];

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyles.header}>
                    {['Account Settings', 'Password'].map((val, index) => (
                        <Button data-test-id={`tab_${index}`} onClick={() => this.handleTabChange(index)} style={this.conditionSort(index == this.state.tabPanel, webStyles.tabBtnActive, webStyles.tabBtn)} variant='text'>{val}</Button>
                    ))}
                </Box>

                <Divider variant='fullWidth' />

                <this.ConditionalPanel conditionArray={[this.state.tabPanel == 0]}>
                    <Box sx={webStyles.formContainer}>

                        <Box sx={webStyles.profileContainer}>
                            <Typography color="secondary" variant='h3'>Your Profile Picture</Typography>
                            <Box sx={webStyles.profileRage}>
                                <Avatar style={webStyles.profileImage} variant='square' src={profileImg} />
                                <Box sx={webStyles.profileActionBtn}>
                                    {GenerateStyledButton(webStyles.profileBtn1, 'contained', 'Update New')}
                                    {GenerateStyledButton(webStyles.profileBtn2, 'contained', 'Remove Profile Picture')}
                                </Box>
                            </Box>
                        </Box>

                        <Formik
                            enableReinitialize
                            initialValues={{
                                full_name: this.state.userData.attributes.full_name,
                                user_name: this.state.userData.attributes.user_name || '',
                                email: this.state.userData.attributes.email,
                                phone_number: this.state.userData.attributes.phone_number,
                                job_title: this.state.userData.attributes.job_title,
                                organization_name: this.state.userData.attributes.organization_name
                            }}
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                                full_name: Yup.string().required('Full Name is required'),
                                user_name: Yup.string(),

                                email: Yup.string().required('Email is required').email("Please enter valid email address"),
                                phone_number: Yup.string().required('Phone No. is required').max(10, 'Phone No. must be 10 digit').min(10, 'Phone No. must be 10 digit'),

                                job_title: Yup.string().required('Job Title is required'),
                                organization_name: Yup.string().required('Organization Name is required'),
                            })}
                            onSubmit={dataObject => {
                                this.handleUpdateField(dataObject)
                            }}
                            render={({
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                values
                            }: FormikProps<any>) => {
                                return (
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <Grid spacing={3} style={webStyles.formGrid} container direction="column">
                                            {this.Genlayout(
                                                { inputLabel: 'Full Name', inputName: "full_name", formikProp: { errors, touched, handleChange, values, handleSubmit } },

                                                { inputLabel: 'User Name', formikProp: { errors, touched, handleChange, values, handleSubmit }, inputName: "user_name" })
                                            }
                                            {this.Genlayout(
                                                { inputLabel: 'Job Title', formikProp: { errors, touched, handleChange, values, handleSubmit }, inputName: "job_title" },

                                                { inputLabel: 'Email Address', formikProp: { errors, touched, handleChange, values, handleSubmit }, inputName: "email" })
                                            }
                                            {this.Genlayout(
                                                { inputLabel: 'Organization Name', formikProp: { errors, touched, handleChange, values }, inputName: "organization_name", handleSubmit },

                                                { inputLabel: 'Phone Number', formikProp: { errors, touched, handleChange, values, handleSubmit }, inputName: "phone_number", keyRule: { fn: this.preventEType, rule: /^[^A-Za-z\W_]+$/ } })
                                            }

                                            <Box marginTop="40px" width={"100%"} display="flex" justifyContent="flex-end">
                                                <Button onClick={() => this.handleModal(true, 'deleteAccModal')} style={webStyles.delAccBtn as React.CSSProperties} variant='text'>
                                                    Delete Account
                                                </Button>
                                            </Box>

                                        </Grid>
                                    </form>
                                );
                            }}
                        />
                    </Box>
                </this.ConditionalPanel>

                <this.ConditionalPanel conditionArray={[this.state.tabPanel == 1]}>
                    <Box>
                        <Formik
                            innerRef={(p) => (this.formikRef = p)}
                            initialValues={{
                                current_password: '',
                                new_password: '',
                                confirm_new_password: ''
                            }}
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                                current_password: Yup.string().required('Current Password is required'),
                                new_password: Yup.string().required('New Password is required').min(8, 'Minimum 8 charcters Required')
                                    .matches(/^(?=.*?[A-Z])(?=.*?[!@#$%^&*])/, 'Password must contain at least one Uppercase letter and one Symbol'),
                                confirm_new_password: Yup.string()
                                    .required('Please retype your password.')
                                    .oneOf([Yup.ref('new_password')], 'Your passwords do not match.')
                            })}
                            onSubmit={dataObject => {
                                this.updatePassword(dataObject)
                            }}
                            render={({
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                values
                            }: FormikProps<any>) => {
                                return (
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <Box sx={webStyles.passwordHeader}>
                                            <Typography gutterBottom variant='h2'>Password</Typography>
                                            <Typography style={{ color: "#BCC5CD" }} variant='body1'>Please enter your current password to change your password</Typography>
                                        </Box>
                                        <Divider variant='fullWidth' />

                                        <Box width="70%" sx={webStyles.passwordInputWrapper}>
                                            {this.GenInput(
                                                { inputLabel: 'Current Password', inputValue: '', inputName: "current_password", enableInput: true },
                                                webStyles.passwordInputContainer,
                                                webStyles.inputRagePassword, '', '', { errors, touched, handleChange, values }, '70%'
                                            )}
                                        </Box>
                                        <Divider variant='fullWidth' />

                                        <Box width="70%" sx={webStyles.passwordInputWrapper}>

                                            {this.GenInput({ inputLabel: 'New Password', inputName: "new_password", enableInput: true }, webStyles.passwordInputContainer, webStyles.inputRagePassword, '', values['new_password'], { errors, touched, handleChange, values }, '70%')}

                                        </Box>
                                        <Divider variant='fullWidth' />

                                        <Box width="70%" sx={webStyles.passwordInputWrapper}>
                                            {this.GenInput({ inputLabel: 'Confirm new password', inputValue: '', inputName: "confirm_new_password", enableInput: true }, webStyles.passwordInputContainer, webStyles.inputRagePassword, '', '', { errors, touched, handleChange, values }, '70%')}
                                        </Box>
                                        <Divider variant='fullWidth' />

                                        <Box sx={webStyles.passwordActionContainer}>
                                            <Button style={webStyles.cancelBtn as React.CSSProperties} variant='outlined'>Cancel</Button>

                                            <Button type="submit" style={webStyles.profileBtn1 as React.CSSProperties} variant='contained'>Update Password</Button>
                                        </Box>
                                    </form>
                                );
                            }}
                        />
                    </Box>
                </this.ConditionalPanel>

                <SettingsModal navigation={undefined} id={''} handleModal={this.handleModal} isOpen={this.state.modalStats} type={this.state.modalType} parentFun={this} />
            </ThemeProvider >
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    formGrid: {
        marginTop: "36px"
    },
    profileContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "23px"
    },
    profileBtn1: {
        "borderRadius": "26px",
        "background": "#9C292D",
        "width": "171px",
        "height": "51px",
        "textTransform": "none",
        color: "white",
        '&:hover': {
            opacity: '0.8',
            "background": "#9C292D",
        }
    },
    profileBtn2: {
        "borderRadius": "26px",
        "background": "rgba(224, 224, 224, 0.67)",
        "textTransform": "none",
        "width": "270px",
        "height": "51px",
        color: "#6F6F70",
        '&:hover': {
            opacity: '0.8',
            "background": "rgba(224, 224, 224, 0.67)",
        }
    },
    profileRage: {
        display: "flex",
        flexDirection: "row",
        gap: "40px",
        alignItems: "center"
    },
    profileActionBtn: {
        display: "flex",
        flexDirection: "row",
        gap: "13px"
    },
    header: {
        height: "90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    formContainer: {
        padding: "35px"
    },
    profileImage: {
        width: "87px",
        height: "81px"
    },
    inputRage: {
        marginTop: "8px",
        width: "100%",
        height: "51px",
        borderRadius: "26px",
        padding: "19px 30px",
        color: "#ACACAC",
        fontSize: "15px",
        border: "1px solid #E0E0E0",
        fontWeight: 300,
        boxSizing: "border-box"
    },
    editBtn: {
        marginTop: "8px",
        width: "100%",
        height: "51px",
        borderRadius: "26px",
        color: "#ACACAC",
        "textTransform": "none",
        border: "1.5px solid #B1B1B1"
    },
    tabBtn: {
        fontSize: "18px",
        fontStyle: 'normal',
        fontWeight: 400,
        color: "#ACACAC",
        textTransform: "none"
    },
    tabBtnActive: {
        fontSize: "18px",
        fontStyle: 'normal',
        fontWeight: 400,
        color: "#9C292D",
        textTransform: "none"
    },
    passwordHeader: {
        height: "128px",
        padding: "0px 48px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    passwordInputWrapper: {
        minHeight: "130px",
        height: "auto",
        padding: "0px 48px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    passwordInputContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    inputRagePassword: {
        marginTop: "8px",
        width: "100%",
        height: "51px",
        borderRadius: "26px",
        padding: "19px 30px",
        color: "#ACACAC",
        fontSize: "15px",
        border: "1px solid #E0E0E0",
        fontWeight: 300,
        "boxSizing": "border-box"
    },
    cancelBtn: {
        "borderRadius": "26px",
        border: "1px solid #C5C5C5",
        "width": "171px",
        "height": "51px",
        "textTransform": "none",
    },
    passwordActionContainer: {
        gap: "14px",
        padding: "31px 64px 29px 64px",
        display: "flex",
        justifyContent: "end",
        alignContent: "center"
    },
    delAccBtn: {
        "textTransform": "none",
        color: "#6B6B6B",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "400",
        '&:hover': {
            opacity: '0.8',
        }
    },
    infoMessage: {
        display: "flex",
        gap: "5px",
        marginTop: "8px",
        color: "#79946E"
    },
    passwordErrorMessage: {
        display: "flex",
        gap: "5px",
        marginTop: "8px",
        color: "#ACACAC"
    },
    passwordWarning: {
        margin: "12px 0px",
        display: "flex",
        flexDirection: "column",
        gap: "3px"
    },
    underlineMessage: {
        textDecorationLine: "underline",
        color: "#79946E",
        display: "flex",
        gap: "5px",
        marginTop: "8px",
    },
}

const theme = createTheme({
    palette: {
        secondary: { main: '#565656' },
        error: { main: "#FF6060" },
        primary: { main: '#096FFF' },
    },
    typography: {
        body1: {
            fontSize: "16px",
            fontStyle: 'normal',
            fontWeight: 400,

        },
        h3: {
            fontSize: "18px",
            fontStyle: 'normal',
            fontWeight: 400,
        },
        h2: {
            fontSize: "20px",
            fontStyle: 'normal',
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400
        },
    },
});

export default WithHomeLayout(Settings)
// Customizable Area End



