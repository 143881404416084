import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    Grid, Chip, FormControlLabel, Checkbox, IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { imgMax } from './assets'
import WithAuthLayout from '../../landingpage/src/CarouselDisplay.web';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// Customizable Area End


// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            "width": "198px",
            "height": "49px",
            "padding": "6px 10px",
            "borderRadius": "36px",
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                height: "35px",
                fontSize: "14px",
            }
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import LoginController, {
    Props
} from "./LoginController";

export class LoginLenderDealer extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    GenInput = (formikProp: any, inputLabel: string, inputName: string, inputType: string, inputPlaceholder: string, enableEyeIcon?: boolean) => {
        return (
            <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>{inputLabel}</Typography>
                <input
                    placeholder={inputPlaceholder}
                    value={formikProp.values[inputName]}
                    onChange={formikProp.handleChange}
                    style={webStyles.inputRage}
                    type={inputType}
                    name={inputName}
                />

                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Grid style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-end'>
                        <InfoOutlinedIcon color='error' fontSize='small' />
                        <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                            {formikProp.errors[inputName]}
                        </Typography>
                    </Grid>
                }

                {enableEyeIcon && <IconButton style={webStyles.eyeIcon as React.CSSProperties}
                    data-test-id="eyeIconBtn"
                    onClick={this.handlePasswordToggle}>
                    {!this.state.passwordEyeToggleForPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>}
            </Box>
        )
    }

    GenCheckbox = (formikProp: any, inputLabel: any, inputName: string,) => {
        return (
            <FormControlLabel
                control={<Checkbox color='primary' name={inputName} checked={formikProp.values[inputName]} onChange={formikProp.handleChange} />}
                label={
                    <Box sx={{ position: "relative" }}>
                        {inputLabel}

                        {formikProp.touched[inputName] && formikProp.errors[inputName] && <Typography style={webStyles.errorMessage as React.CSSProperties} color='error' variant='subtitle2'>
                            <span><InfoOutlinedIcon color='error' fontSize='small' /></span>
                            {formikProp.errors[inputName]}
                        </Typography>}

                    </Box>
                }
            />
        )
    }

    RenderChip = (isSelected: boolean, onClickHandler: () => void, label: string) => (
        <Chip
            onClick={onClickHandler}
            data-test-id="selectUser"
            style={isSelected ? webStyles.activeChipRage : webStyles.chipRage}
            label={<Typography variant='body1'>{label}</Typography>}
        />
    );
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container justifyContent="flex-end" >
                    <img src={imgMax} alt="img2" height={68} width={43} />
                </Grid>

                <Grid container direction="column" justifyContent="center" alignItems="center">

                    <Grid direction="column" style={webStyles.formContainer} container justifyContent="center" alignItems="center">

                        <Typography variant='h6'>Welcome to ReserveMax.ai..!</Typography>

                        {this.props.typeAdmin && <Chip style={webStyles.chipRage} label={<Typography variant='body1'>Login</Typography>} />}

                        {!this.props.typeAdmin && <Box sx={webStyles.selectUser}>
                            {this.RenderChip(this.state.userLenderType, () => this.handleUserSelection(true), 'Lender')}
                            {this.RenderChip(!this.state.userLenderType, () => this.handleUserSelection(false), 'Dealership')}
                        </Box>}

                        <Typography style={{ marginTop: "30px", color: "#5B5B5B" }} variant='body1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry  Ipsum Ipsum Ipsum.</Typography>

                        <Formik
                            innerRef={(p) => (this.formikRef = p)}
                            enableReinitialize
                            initialValues={{
                                email: this.RememberMeConditionSort(localStorage.getItem("Rem_Email"), ''),
                                password: this.RememberMeConditionSort(localStorage.getItem("Rem_Password"), ''),
                                remember_me: false,
                                terms_condition: false
                            }}
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .required("Please enter an email")
                                    .email("Please enter valid email address"),
                                password: Yup.string()
                                    .required("Please enter an password"),
                                remember_me: Yup.boolean(),
                                terms_condition: Yup.boolean()
                                    .test('is-true', 'You must accept the privacy policy before continuing.', value => value),
                            })}
                            onSubmit={dataObject => {
                                this.doEmailLogIn(dataObject)
                            }}
                            render={({
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                values
                            }: FormikProps<any>) => {
                                return (
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <Box style={webStyles.formWrapper as React.CSSProperties}>
                                            {this.GenInput(
                                                { errors, touched, handleChange, handleBlur, values },
                                                "Email address", "email", "text", "Enter your email address")}
                                            {this.GenInput(
                                                { errors, touched, handleChange, handleBlur, values },
                                                "Password", "password", this.state.passwordEyeToggleForPassword ? 'password' : 'text', "Enter your Password", true)}
                                        </Box>

                                        <Grid style={{ marginTop: "16px" }} container justifyContent="space-between" alignItems="center">

                                            {this.GenCheckbox(
                                                { errors, touched, handleChange, handleBlur, values },
                                                <Typography variant='caption'>Remember me</Typography>,
                                                'remember_me'
                                            )}

                                            <Typography variant='subtitle2'>
                                                <Link style={webStyles.linkType} to="/ForgotPassword">
                                                    Forgot Password ?
                                                </Link>
                                            </Typography>

                                        </Grid>

                                        <Grid direction='row' style={{ marginTop: "49px" }} container justifyContent="space-between" alignItems="center">

                                            {this.GenCheckbox(
                                                { errors, touched, handleChange, handleBlur, values },
                                                <Typography variant='caption'>I agree with
                                                    <span style={{ color: "#4743E0" }}> terms and conditions.</span>
                                                </Typography>,
                                                'terms_condition'
                                            )}

                                            <StyledButton data-test-id="formSubmitBtn" type="submit" variant='contained'>Login</StyledButton>
                                        </Grid>
                                    </form>
                                );
                            }}
                        />

                        <Typography style={{ marginTop: "47px", color: "#C2C1C0" }} variant='subtitle1'>Don’t have an account yet? <span style={{ color: "#9C292D" }}>
                            <Link style={webStyles.linkType} to="/EmailAccountRegistration">
                                Register here
                            </Link>
                        </span>
                        </Typography>

                        <Typography style={{ color: "#4743E0" }} variant='caption'>learn more</Typography>

                    </Grid>

                </Grid>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    linkType: {
        color: 'inherit',
        textDecoration: 'none'
    },
    chipRage: {
        width: "146px",
        height: "40px",
        borderRadius: "33px",
        "background": "#F0F0F0"
    },
    activeChipRage: {
        width: "146px",
        height: "40px",
        borderRadius: "33px",
        color: "#FFF",
        "background": "#CB494C"
    },
    formContainer: {
        height: "auto",
        maxWidth: "454px"
    },
    inputRage: {
        marginTop: "18px",
        width: "100%",
        height: "54px",
        borderRadius: "40px",
        border: "1px solid #9E896A",
        padding: "19px 30px",
        color: "#ACACAC",
        fontSize: "15px",
        fontWeight: 300
    },
    formWrapper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "23px",
        marginTop: "34px"
    },
    errorMessage: {
        display: "flex",
        gap: "3px",
        position: "absolute",
        left: "-5px"
    },
    eyeIcon: {
        right: "10px",
        position: "absolute",
        top: "45px"
    },
    selectUser: {
        "width": "329px",
        "height": "59px",
        "borderRadius": "33px",
        "background": "#F0F0F0",
        marginTop: "17px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}

const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: '#FFF' },
        error: { main: "#FF6060" }
    },
    typography: {
        // "fontFamily": `Abadi-MT-Condensed-Extra-Bold-Regular-Font,Rubik Microbe`,
        h6: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 800
        },
        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        caption: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 300
        },
        subtitle1: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        subtitle2: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400
        },
    },
});

export default WithAuthLayout(LoginLenderDealer, true, true)
// Customizable Area End


