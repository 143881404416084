import React from 'react'

// Customizable Area Start
import {
    Button,
    Grid, IconButton,
    Box,
    Typography
} from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import WithAuthLayout from '../../landingpage/src/CarouselDisplay.web';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { imgMax, ellipse } from './assets'
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// Customizable Area End


// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            "borderRadius": "36px",
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: 400,
            "width": "198px",
            "height": "49px",
            "padding": "6px 10px",
            textTransform: "none",
            '&:hover': {
                backgroundColor: "#9C292D",
                opacity: "0.7"
            },
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                fontSize: "14px",
                height: "35px",
            }
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import ChangePasswordController, {
    Props
} from "./ChangePasswordController";

export class NewPassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    GenInput = (formikProp: any, inputLabel: string, inputName: string, inputType: string, inputPlaceholder: string, enableEyeIcon?: boolean) => {
        return (
            <Box data-test-id="np2" sx={{ width: { xs: "100%", md: "96%", lg: "96%" }, position: "relative" }}>
                <Typography variant='body1'>{inputLabel}</Typography>
                <input
                    value={formikProp.values[inputName]}
                    style={webStyles.inputRage}
                    placeholder={inputPlaceholder}
                    onChange={formikProp.handleChange}
                    type={inputType}
                    name={inputName}
                />

                {enableEyeIcon && <IconButton style={webStyles.eyeIcon as React.CSSProperties}
                    data-test-id="eyeIconBtn"
                    onClick={() => this.handlePasswordToggle(inputName)}>
                    {this.state.passwordEyeToggleForPassword[inputName] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>}

                {formikProp.touched[inputName] && formikProp.errors[inputName] &&
                    <Grid data-test-id="np" style={{ marginTop: "8px" }} container alignItems='center' justifyContent='flex-end'>
                        <InfoOutlinedIcon data-test-id="np1" color='error' fontSize='small' />

                        <Typography color='error' variant='subtitle2' style={{ marginLeft: "3px" }}>
                            {formikProp.errors[inputName]}
                        </Typography>

                    </Grid>
                }
            </Box>
        )
    }

    PasswordRequirements = (requirements: any, newPassword: any) => {
        const validationResults = requirements.map((data: any) => data.regex.test(newPassword));

        let status = requirements.map((data: any, index: any) => {
            const Icon = validationResults[index] ? CheckCircleIcon : InfoOutlinedIcon;
            const style = validationResults[index] ? webStyles.infoMessage : webStyles.errorMessage;

            return (
                <Typography style={style} variant='subtitle2' key={index}>
                    <span><Icon color='error' fontSize='small' /></span>
                    {data.message}
                </Typography>
            );
        });

        return (
            <Box sx={webStyles.passwordWarning}>
                <Typography style={webStyles.underlineMessage} variant='subtitle2'>
                    <span><InfoOutlinedIcon color='error' fontSize='small' /></span>
                    Password Requirements:
                </Typography>
                {status}
            </Box>
        );
    };

    requirements = [
        { regex: /.{8,}/, message: "At least 8 characters long" },
        { regex: /[A-Z]/, message: "At least 1 uppercase character" },
        { regex: /[a-z]/, message: "At least 1 lowercase character" },
        { regex: /\d/, message: "At least 1 number" },
        { regex: /[!@#$%^&*()\-_=+{};:,<.>]/, message: "At least 1 special character" }
    ];

    // Customizable Area End




    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid data-test-id="imgNp" container justifyContent="flex-end" >
                    <img src={imgMax} alt="img2" height={68} width={43} />
                </Grid>

                <Grid data-test-id="np4" container direction="column" justifyContent="center" alignItems="center" >
                    <Grid direction="column" style={webStyles.formContainer} container justifyContent="center" alignItems="center">

                        <Box data-test-id="np5" sx={{ position: "relative" }}>
                            <img src={ellipse} alt="img2" height={72} width={72} />
                            <LockIcon data-test-id="lockIcon" style={webStyles.lockIconRage as React.CSSProperties} color='secondary' />
                        </Box>

                        <Typography style={{ marginTop: "12px" }} variant='h2'>Change Password</Typography>
                        <Typography style={{ marginTop: "12px", color: "#5C5F62" }} variant='body1'>Input your new desired password</Typography>

                        <Box sx={{ width: "100%" }}>
                            <Formik
                                initialValues={{
                                    new_password: '',
                                    confirm_password: ''
                                }}
                                validateOnBlur={false}
                                validationSchema={Yup.object().shape({
                                    new_password: Yup.string().required('Password is required').min(8, 'Minimum 8 charcters Required')
                                        .matches(/^(?=.*?[A-Z])(?=.*?[!@#$%^&*])/, 'Password must contain at least one Uppercase letter and one Symbol'),
                                    confirm_password: Yup.string()
                                        .required('Please retype your password.')
                                        .oneOf([Yup.ref('new_password')], 'Your passwords do not match.')
                                })}
                                onSubmit={dataObject => {
                                    this.changePassword(dataObject)
                                }}
                                render={({
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    values
                                }: FormikProps<any>) => {
                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                        >
                                            <Box style={webStyles.formWrapper as React.CSSProperties}>
                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "New Password", "new_password", this.state.passwordEyeToggleForPassword["new_password"] ? "text" : "password", "Enter your Password", true)}
                                                {this.GenInput(
                                                    { errors, touched, handleChange, handleBlur, values },
                                                    "Confirm New Password", "confirm_password", this.state.passwordEyeToggleForPassword["confirm_password"] ? "text" : "password", "Enter your Password", true)}
                                            </Box>

                                            {values.new_password && this.PasswordRequirements(this.requirements, values.new_password)}


                                            <Grid direction='row' style={{ marginTop: "40px" }} container justifyContent="space-between" alignItems="center">
                                                <Box>
                                                    <Typography style={{ color: "#C2C1C0" }} variant='subtitle1'>
                                                        Do you need help?
                                                    </Typography>
                                                    <Typography style={{ color: "#9C292D" }} variant='subtitle1'>
                                                        Customer support
                                                    </Typography>
                                                </Box>

                                                <StyledButton data-test-id="formSubmitBtn" type="submit" variant='contained'>
                                                    Change password
                                                </StyledButton>
                                            </Grid>
                                        </form>
                                    );
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    inputRage: {
        marginTop: "18px",
        width: "100%",
        height: "54px",
        borderRadius: "40px",
        border: "1px solid #9E896A",
        padding: "19px 30px",
        color: "#ACACAC",
        fontSize: "15px",
        fontWeight: 300
    },
    lockIconRage: {
        "position": "absolute",
        "top": "15px",
        "right": "15px",
        fontSize: "40px"
    },
    eyeIcon: {
        right: "10px",
        position: "absolute",
        top: "45px"
    },
    formContainer: {
        height: "auto",
        maxWidth: "454px"
    },
    formWrapper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "23px",
        marginTop: "40px"
    },
    infoMessage: {
        display: "flex",
        gap: "5px",
        marginTop: "8px",
        color: "#79946E"
    },
    errorMessage: {
        display: "flex",
        gap: "5px",
        marginTop: "8px",
        color: "#ACACAC"
    },
    underlineMessage: {
        textDecorationLine: "underline",
        color: "#79946E",
        display: "flex",
        gap: "5px",
        marginTop: "8px",
    },
    passwordWarning: {
        marginTop: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "3px"
    }
}

const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        error: { main: "#FF6060" },
        secondary: { main: '#FFF' }
    },
    typography: {
        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        h6: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 800
        },
        subtitle2: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400
        },
        subtitle1: {
            fontSize: "14px",
            fontStyle: 'normal',
            fontWeight: 400
        },
        h2: {
            fontSize: "24px",
            fontStyle: 'normal',
            fontWeight: 800
        }
    },
});

export default WithAuthLayout(NewPassword)
// Customizable Area End


