import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import ToastMessage from "../../../components/src/ToastMessage.web";
import React from 'react'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen?: boolean;
  handleModal?: any;
  type?: any;
  parentFun?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabPanel: number;
  userData: any;
  enableEdit: any;
  inputData: any;
  modalStats: boolean;
  modalType: any;
  otpValue: any;
  otpError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDataCallId: string = "";
  updateUserNameCallId: string = "";
  updatePasswordCallId: string = "";
  updateContactNoCallId: string = "";
  verifyEmailCallId: string = "";
  verifyOTPCallId: string = "";
  updateEmailCallId: string = "";
  deleteAccountCallId: string = "";
  inputRefs: any = {};
  formikRef: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabPanel: 0,
      userData: {
        "id": "",
        "type": "",
        "attributes": {
          "full_name": '',
          "user_name": '',
          "email": "",
          "phone_number": '',
          "job_title": "",
          "organization_name": "",
          "image_url": ''
        }
      },
      enableEdit: {},
      inputData: {},
      modalStats: false,
      modalType: '',
      otpValue: "",
      otpError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleResForGetUserData(from, message)
    this.handleResForUpdateUserName(from, message)
    this.handleResForUpdateUserPassword(from, message)
    this.handleResForUpdateContactNo(from, message)
    this.handleResForVerifyEmail(from, message)
    this.handleResForVerifyOTP(from, message)
    this.handleResForUpdateEmail(from, message)
    this.handleResForDeleteAccount(from, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  ConditionalPanel = (userProps: {
    children: React.ReactNode;
    conditionArray: Array<boolean | string>;
  }) => {
    const { children, conditionArray } = userProps;
    const condition = conditionArray.every((state: boolean | string) => state);
    return condition ? <>{children}</> : null;
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserData()
  }

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  }

  handleTabChange = (index: number) => {
    if (index == 0) {
      this.getUserData()
    }
    this.setState({ tabPanel: index })
  }

  handleEditBtn = (forInput: any, handleSubmit: any) => {
    if (this.state.enableEdit == forInput) {
      return handleSubmit()
    }
    this.setState({ enableEdit: forInput }, () => this.inputRefs[forInput].focus());
  }

  FormatError = (errors: any[]) => {
    let allerrors = "";
    errors.forEach(object => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          allerrors =
            allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
        }
      });
    });
    return allerrors;
  };

  handleInputChange = (e: any) => {
    this.setState({ inputData: { ...this.state.inputData, [e.target.name]: e.target.value } })
  }

  preventEType = (e: any, regex: any) => {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Shift", "+"];
    return (allowedKeys.includes(e.key) ? false : !regex.test(e.key)) && e.preventDefault();
  }

  handleUpdateField = (dataObject: any) => {
    if (this.state.userData.attributes.user_name != dataObject.user_name && dataObject.user_name.length > 0) {
      return this.updateUserNameData(dataObject)
    }
    if (this.state.userData.attributes.phone_number != dataObject.phone_number) {
      return this.updateContactNo(dataObject)
    }
    if (this.state.userData.attributes.email != dataObject.email) {
      return this.handleEmailChangeFlow(dataObject.email)
    }
    this.setState({ enableEdit: '' })
  }

  handleModal = (stats: any, type: any) => {
    this.setState({ modalStats: stats, modalType: type })
  }

  handleChangeOTP = (value: any) => {
    this.setState({
      otpValue: value,
      otpError: false
    });
  };

  handleEmailChangeFlow = (email: any) => {
    localStorage.setItem('newEmail', email)
    this.handleModal(true, 'askEmailVerification')
  }

  // API Function
  getUserData = () => {
    const header = {
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/specific_account'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateUserNameData = (dataObject: any) => {
    const header = {
      token: localStorage.getItem('authToken'),
      'content-type': 'application/json'
    };
    const httpBody = {
      user_name: dataObject.user_name
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserNameCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_account?id=${this.state.userData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updatePassword = (dataObject: any) => {

    const header = {
      token: localStorage.getItem('authToken'),
      'content-type': 'application/json'
    };

    const httpBody = {
      old_password: dataObject.current_password,
      new_password: dataObject.new_password,
      confirm_new_password: dataObject.confirm_new_password
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/password_update`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateContactNo = (dataObject: any) => {
    const header = {
      token: localStorage.getItem('authToken'),
      'content-type': 'application/json'
    };
    const httpBody = {
      full_phone_number: `91${dataObject.phone_number}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateContactNoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/change_phone_number`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  emailVerify = () => {
    const header = {
      token: localStorage.getItem('authToken'),
      'content-type': 'application/json'
    };
    const httpBody = {
      data: {
        attributes: {
          email: localStorage.getItem('newEmail')
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/send_otps`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyOTP = () => {
    if (this.state.otpValue.length !== 6) {
      return this.setState({ otpError: true })
    }
    const header = {
      token: localStorage.getItem('changeEmailToken'),
      'content-type': 'application/json'
    };

    const httpBody = {
      otp_code: this.state.otpValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/otp_confirmations`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateEmail = (dataObject: any) => {
    const header = {
      token: localStorage.getItem('authToken'),
      'content-type': 'application/json'
    };
    const httpBody = {
      email: dataObject.new_email,
      email_confirmations: dataObject.confirm_email
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/change_email_address`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAccount = () => {
    const header = {
      token: localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/delete_account`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  //API Response Function
  handleResForGetUserData = async (from: string, message: Message) => {
    if (this.getUserDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ userData: responseJson.data, inputData: responseJson.data.attributes })
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForUpdateUserName = async (from: string, message: Message) => {
    if (this.updateUserNameCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ userData: responseJson.data.data, enableEdit: '' })
        ToastMessage("success", responseJson.message);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForUpdateUserPassword = async (from: string, message: Message) => {
    if (this.updatePasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ inputData: {} })
        ToastMessage("success", "Password Updated Successfully");
        this.formikRef.resetForm()
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForUpdateContactNo = async (from: string, message: Message) => {
    if (this.updateContactNoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ userData: responseJson.data.data, enableEdit: '' })
        ToastMessage("success", responseJson.message);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForVerifyEmail = async (from: string, message: Message) => {
    if (this.verifyEmailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data && responseJson.meta) {
        this.setState({ modalType: 'sendOTPPage' })
        localStorage.setItem('changeEmailToken', responseJson.data.token)
        ToastMessage("success", responseJson.meta.message);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForVerifyOTP = async (from: string, message: Message) => {
    if (this.verifyOTPCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.setState({ modalType: 'emailInput' })
        ToastMessage("success", responseJson.message);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForUpdateEmail = async (from: string, message: Message) => {
    if (this.updateEmailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.handleModal(false, '')
        this.setState({ enableEdit: '' }, () => this.getUserData())
        ToastMessage("success", responseJson.message);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }

  handleResForDeleteAccount = async (from: string, message: Message) => {
    if (this.deleteAccountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.success) {
        localStorage.removeItem('authToken')
        this.props.navigation.navigate("LoginLenderDealer")
        ToastMessage("success", responseJson.message);
      } else {
        let allerrors = this.FormatError(responseJson.errors);
        ToastMessage("error", allerrors || "Something went Wrong");
      }
    }
  }
  // Customizable Area End
}
