import React from 'react'

// Customizable Area Start
import {
    Button,
    Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WithHomeLayout from './WithHomeLayout.web';
// Customizable Area End


// Customizable Area Start

// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";

export class DummyTestPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <ThemeProvider theme={theme}>

                <Box sx={webStyles.pages}>
                    <Button data-test-id="logoutBtn" onClick={this.logout} color='primary' variant='contained'>Logout</Button>
                </Box>

            </ThemeProvider >
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    pages: {
        height:"500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}

const theme = createTheme({
    palette: {
        secondary: { main: '#FFF' },
        error: { main: "#534ee6" },
        primary: { main: '#096FFF' },
    },
    typography: {
        subtitle2: {
            fontSize: "16px",
            fontStyle: 'normal',
            fontWeight: 400,
            color: "#BCC5CD"
        },
        body1: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 400,
        }
    },
});

export default WithHomeLayout(DummyTestPage)
// Customizable Area End


