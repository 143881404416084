import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    Grid, CircularProgress, FormControlLabel, Checkbox, Select, MenuItem, IconButton, Dialog
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area End


// Customizable Area Start
export const CustomDialog: any = withStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: "40px",
            opacity: "0.7"
        }
    }),
)((props: any) => <Dialog {...props} />);

export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            color: "#FFF",
            fontSize: "16px",
            "borderRadius": "36px",
            "width": "198px",
            textTransform: "none",
            fontWeight: 400,
            "height": "49px",
            "padding": "6px 10px",
            [theme.breakpoints.down('xs')]: {
                width: "120px",
                height: "35px",
                fontSize: "14px",
            },
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            fontWeight: 400,
            minWidth: "0px",
            textTransform: "none",
            '&:hover': {
                opacity: "0.7"
            },
            fontSize: "14px",
            fontStyle: 'normal',
            color: "#9C292D",
            "padding": "0px"
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import ModalController, {
    Props
} from "./ModalController";

class ConfirmationModal extends ModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    CircularProgressWithLabel = (data: any) => {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress size={80} variant="determinate" {...data} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        data.label
                    )}s`}</Typography>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <CustomDialog
                    fullWidth
                    maxWidth="sm"
                    open={this.props.isOpen}
                    onClose={() => this.props.handleModal(false)}
                >

                    <Box sx={webStyles.dialogWrapper}>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                            <IconButton data-test-id='closeBtn' onClick={() => this.props.handleModal(false)}>
                                <CancelIcon color="secondary" />
                            </IconButton>
                        </Grid>
                        <Typography gutterBottom align='center' variant='h1'>Success</Typography>
                        <Typography align='center' variant='body1'>We have succsefuly created your new account. But before you start you will have to activate it. We have sent an activation mail to the email you provided during registration. It should arrive in a couple of minutes</Typography>

                        <Typography style={{ margin: "65px 0 20px 0" }} align='center' variant='body1'>IF the email has not arrived during 30s you can still click the button below to resend it. We guarantee it will come this time! </Typography>

                        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                            <this.CircularProgressWithLabel value={this.normalizeValue(this.props.timer, 30)} label={this.props.timer} />

                            <StyledButton2 data-test-id="resendConfirmationBtn" onClick={this.resendOTPConfirmation} variant="text">Re-send the verification email</StyledButton2>

                            <StyledButton onClick={() => this.props.handleModal(false)} variant="contained" color="primary">Close</StyledButton>
                        </Grid>
                    </Box>
                </CustomDialog>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    dialogWrapper: {
        boxSizing: "border-box",
        padding: "24px 30px"
    }
}

const theme = createTheme({
    palette: {
        primary: { main: '#9C292D' },
        secondary: { main: '#E8C4C6' },
        error: { main: "#FF6060" },
    },
    typography: {
        // "fontFamily": `Abadi-MT-Condensed-Extra-Bold-Regular-Font,Rubik Microbe`,
        h1: {
            fontSize: '54px',
            fontStyle: 'normal',
            fontWeight: 400,
            color: "#42505C"
        },
        body1: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            color: "#404040"
        }
    },
});

export default ConfirmationModal
// Customizable Area End


